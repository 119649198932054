import React, { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Table } from 'antd';

import Title from 'components/Title';
import Spacer from 'components/Spacer';
import Container from 'components/Container';
import Pagination from 'components/Pagination';

import cls from './Collegial.module.scss';

const SIZE = 10;

const Collegial = () => {
  const { t } = useTranslation();
  const [page, setPage] = useState(1);

  const items = useMemo(() => [
     {
        "№": 2,
        id: 1,
        name: "Respublika maʼnaviyat va maʼrifat kengashi",
        description: "O‘zbekiston Respublikasi Prezidentining 1999 yil 3 sentyabrdagi  PF–2385-son “Respublika maʼnaviyat va maʼrifat kengashini qo‘llab-quvvatlash to‘g‘risida”gi Farmoni",
        organing: "-"
     },
     {
        "№": 3,
        id: 2,
        name: "2017-2021 yillarda O‘zbekiston Respublikasini rivojlantirishning beshta ustuvor yo‘nalishi bo‘yicha Harakatlar strategiyasini amalga oshirish bo‘yicha Milliy komissiya",
        description: "O‘zbekiston Respublikasi Prezidentining 2017 yil 7 fevraldagi “O‘zbekiston Respublikasini yana-da rivojlantirish bo‘yicha Harakatlar strategiyasi to‘g‘risida”gi PF–4947-son Farmoni",
        organing: "-"
     },
     {
        "№": 4,
        id: 3,
        name: "Iqtisodiyotni rivojlantirishning ustuvor yo‘nalishlarini amalga oshirish bo‘yicha komissiya",
        description: "O‘zbekiston Respublikasi Prezidentining 2017 yil 7 fevraldagi “O‘zbekiston Respublikasini yana-da rivojlantirish bo‘yicha Harakatlar strategiyasi to‘g‘risida”gi PF–4947-son Farmoni",
        organing: "-"
     },
     {
        "№": 5,
        id: 4,
        name: "Davlat va jamiyat qurilishi tizimini takomillashtirishning ustuvor yo‘nalishlarini amalga oshirish bo‘yicha komissiya",
        description: "O‘zbekiston Respublikasi Prezidentining 2017 yil 7 fevraldagi “O‘zbekiston Respublikasini yana-da rivojlantirish bo‘yicha Harakatlar strategiyasi to‘g‘risida”gi PF–4947-son Farmoni",
        organing: "-"
     },
     {
        "№": 6,
        id: 5,
        name: "Qonun ustuvorligini taʼminlash va sud-huquq tizimini yanada isloh qilishning ustuvor yo‘nalishlarini amalga oshirish bo‘yicha komissiya",
        description: "O‘zbekiston Respublikasi Prezidentining 2017 yil 7 fevraldagi “O‘zbekiston Respublikasini yana-da rivojlantirish bo‘yicha Harakatlar strategiyasi to‘g‘risida”gi PF–4947-son Farmoni",
        organing: "-"
     },
     {
        "№": 7,
        id: 6,
        name: "Ijtimoiy sohani rivojlantirishning ustuvor yo‘nalishlarini amalga oshirish bo‘yicha komissiya",
        description: "O‘zbekiston Respublikasi Prezidentining 2017 yil 7 fevraldagi “O‘zbekiston Respublikasini yana-da rivojlantirish bo‘yicha Harakatlar strategiyasi to‘g‘risida”gi PF–4947-son Farmoni",
        organing: "-"
     },
     {
        "№": 8,
        id: 7,
        name: "Xavfsizlik, diniy bag‘rikenglik va millatlararo totuvlikni taʼminlash hamda chuqur o‘ylangan, o‘zaro manfaatli va amaliy tashqi siyosat sohasidagi ustuvor yo‘nalishlarini amalga oshirish bo‘yicha komissiya",
        description: "O‘zbekiston Respublikasi Prezidentining 2017 yil 7 fevraldagi “O‘zbekiston Respublikasini yana-da rivojlantirish bo‘yicha Harakatlar strategiyasi to‘g‘risida”gi PF–4947-son Farmoni",
        organing: "-"
     },
     {
        "№": 9,
        id: 8,
        name: "Ichki ishlar organlari tizimini tubdan isloh qilish kompleks chora-tadbirlar Dasturini amalga oshirish bo‘yicha Maxsus komissiya",
        description: "O‘zbekiston Respublikasi Prezidentining 2017 yil 10 apreldagi “Ichki ishlar organlarining faoliyati samaradorligini tubdan oshirish, jamoat tartibini, fuqarolar huquqlari, erkinliklari va qonuniy manfaatlarini ishonchli himoya qilishni taʼminlashda ularning masʼuliyatini kuchaytirish chora-tadbirlari to‘g‘risida”gi PF–5005-son Farmoni",
        organing: "-"
     },
     {
        "№": 10,
        id: 9,
        name: "Favqulodda vaziyatlarning oldini olish va ularni bartaraf etish bo‘yicha Hukumat komissiyasi",
        description: "O‘zbekiston Respublikasi Prezidentining 2017 yil 1 iyundagi “Favqulodda vaziyatlarning oldini olish va ularni bartaraf etish tizimi samaradorligini tubdan oshirish chora-tadbirlari to‘g‘risida”gi PF–5066-son Farmoni",
        organing: "-"
     },
     {
        "№": 11,
        id: 10,
        name: "Yoshlar masalalari bo‘yicha respublika idoralararo kengashi",
        description: "O‘zbekiston Respublikasi Prezidentining 2017 yil 5 iyuldagi “Yoshlarga oid davlat siyosati samaradorligini oshirish va O‘zbekiston Yoshlar ittifoqi faoliyatini qo‘llab-quvvatlash to‘g‘risida”gi PF–5106-son Farmoni",
        organing: "-"
     },
     {
        "№": 12,
        id: 11,
        name: "Soliq qonunchiligi va soliq maʼmuriyatchiligini yana-da takomillashtirish bo‘yicha ishchi komissiya",
        description: "O‘zbekiston Respublikasi Prezidentining 2017 yil 18 iyuldagi “Soliq maʼmuriyatchiligini tubdan takomillashtirish, soliqlar va boshqa majburiy to‘lovlarning yig‘iluvchanligini oshirish chora-tadbirlari to‘g‘risida”gi  PF–5116-son Farmoni",
        organing: "-"
     },
     {
        "№": 13,
        id: 12,
        name: "O‘zbekiston Respublikasida Maʼmuriy islohotlar konsepsiyasini amalga oshirish bo‘yicha komissiya",
        description: "O‘zbekiston Respublikasi Prezidentining 2017 yil 8 sentyabrdagi “O‘zbekiston Respublikasida Maʼmuriy islohotlar konsepsiyasini tasdiqlash to‘g‘risida”gi PF–5185-son Farmoni",
        organing: "-"
     },
     {
        "№": 14,
        id: 13,
        name: "Yoshlar tadbirkorligini rivojlantirish bo‘yicha respublika komissiyasi",
        description: "O‘zbekiston Respublikasi Prezidentining 2018 yil 27 iyundagi “Yoshlar – kelajagimiz” Davlat dasturi to‘g‘risida”gi PF–5466-son Farmoni",
        organing: "-"
     },
     {
        "№": 15,
        id: 14,
        name: "Norma ijodkorligi faoliyatini takomillashtirish konsepsiyasini amalga oshirish bo‘yicha komissiya",
        description: "O‘zbekiston Respublikasi Prezidentining 2018 yil 8 avgustdagi “Norma ijodkorligi faoliyatini takomillashtirish konsepsiyasini tasdiqlash to‘g‘risida”gi PF–5505-son Farmoni",
        organing: "-"
     },
     {
        "№": 16,
        id: 15,
        name: "Terroristik, ekstremistik yoki boshqa taqiqlangan tashkilot va guruhlar tarkibiga adashib kirib qolgan O‘zbekiston Respublikasi fuqarolarining murojaatlarini ko‘rib chiqish bo‘yicha Respublika idoralararo komissiyasi",
        description: "O‘zbekiston Respublikasi Prezidentining 2018 yil 19 sentyabrdagi “Terroristik, ekstremistik yoki boshqa taqiqlangan tashkilot va guruhlar tarkibiga adashib kirib qolgan O‘zbekiston Respublikasi fuqarolarini jinoiy javobgarlikdan ozod etish tartibini takomillashtirish to‘g‘risida”gi PF–5542-son Farmoni",
        organing: "-"
     },
     {
        "№": 17,
        id: 16,
        name: "2019-2021 yillarda O‘zbekiston Respublikasini innovatsion rivojlantirish strategiyasini o‘z vaqtida va sifatli amalga oshirish bo‘yicha komissiya",
        description: "O‘zbekiston Respublikasi Prezidentining 2018 yil 21 sentyabrdagi  “2019-2021 yillarda O‘zbekiston Respublikasini innovatsion rivojlantirish strategiyasini tasdiqlash to‘g‘risida”gi PF–5544-son Farmoni",
        organing: "-"
     },
     {
        "№": 18,
        id: 17,
        name: "Sog‘liqni saqlash tizimini tubdan takomillashtirishga qaratilgan chora-tadbirlarni amalga oshirish bo‘yicha ishchi komissiya",
        description: "O‘zbekiston Respublikasi Prezidentining 2018 yil 7 dekabrdagi “O‘zbekiston Respublikasi sog‘liqni saqlash tizimini tubdan takomillashtirish bo‘yicha kompleks chora-tadbirlar to‘g‘risida”gi PF–5590-son Farmoni",
        organing: "-"
     },
     {
        "№": 19,
        id: 18,
        name: "O‘zbekistonning logistika jozibadorligini tubdan yaxshilash, shuningdek, mahalliy aeroportlarda parvozlarga xorijiy aviakompaniyalarni jalb etish ishlarini muvofiqlashtirish bo‘yicha ishchi guruh",
        description: "O‘zbekiston Respublikasi Prezidentining 2019 yil 5 yanvardagi “O‘zbekiston Respublikasida turizmni jadal rivojlantirishga oid qo‘shimcha chora-tadbirlar to‘g‘risida”gi PF–5611-son Farmoni",
        organing: "-"
     },
     {
        "№": 20,
        id: 19,
        name: "Qishloq xo‘jaligida yer va suv resurslaridan samarali foydalanish konsepsiyasini amalga oshirish doirasida xalqaro moliya institutlari bilan hamkorlik qilish va davlat-xususiy sheriklikni joriy etish bo‘yicha ishchi guruhi",
        description: "O‘zbekiston Respublikasi Prezidentining “Qishloq xo‘jaligida yer va suv resurslaridan samarali foydalanish chora-tadbirlari to‘g‘risida” 2019 yil  17 iyundagi PF–5742-son Farmoni",
        organing: "-"
     },
     {
        "№": 21,
        id: 20,
        name: "Odam savdosiga va majburiy mehnatga qarshi kurashish milliy komissiyasi",
        description: "O‘zbekiston Respublikasi Prezidentining 2019 yil 30 iyuldagi “Odam savdosiga va majburiy mehnatga qarshi kurashish tizimini yana-da takomillashtirishga oid qo‘shimcha chora-tadbirlar to‘g‘risida”gi PF–5775-son Farmoni",
        organing: "-"
     },
     {
        "№": 22,
        id: 21,
        name: "“O‘zbekiston turizm magistrali” turizm transport koridori bo‘ylab avtomagistral yo‘llari va yo‘lbo‘yi infratuzilmasi holatini o‘rganish bo‘yicha ishchi guruh",
        description: "O‘zbekiston Respublikasi Prezidentining 2019 yil 13 avgustdagi “O‘zbekiston Respublikasida turizm sohasini yana-da rivojlantirish chora-tadbirlari to‘g‘risida”gi PF–5781-son Farmoni",
        organing: "-"
     },
     {
        "№": 23,
        id: 22,
        name: "Tashqi mehnat migratsiyasi masalalari bo‘yicha respublika komissiyasi",
        description: "O‘zbekiston Respublikasi Prezidentining 2019 yil 20 avgustdagi “Xorijda vaqtinchalik mehnat faoliyatini amalga oshirayotgan O‘zbekiston Respublikasi fuqarolari va ularning oila aʼzolarini himoya qilish kafolatlarini yana-da kuchaytirish choralari to‘g‘risida”gi PF–5785-son Farmoni",
        organing: "-"
     },
     {
        "№": 24,
        id: 23,
        name: "Tashqi beg‘araz ko‘makni jalb etish masalalari bo‘yicha muvofiqlashtiruvchi kengash",
        description: "O‘zbekiston Respublikasi Prezidentining 2019 yil 10 oktyabrdagi “Tashqi beg‘araz ko‘mak mablag‘larini jalb etish mexanizmlarini va donorlar bilan ishlashni takomillashtirish chora-tadbirlari to‘g‘risida”gi PF-5848-son Farmoni",
        organing: "-"
     },
     {
        "№": 25,
        id: 24,
        name: "O‘zbekiston Respublikasi qishloq xo‘jaligini rivojlantirishning 2020 — 2030 yillarga mo‘ljallangan strategiyasini amalga oshirish bo‘yicha muvofiqlashtiruvchi kengash",
        description: "O‘zbekiston Respublikasi Prezidentining 2019 yil 23 oktyabrdagi “O‘zbekiston Respublikasi qishloq xo‘jaligini rivojlantirishning 2020–2030 yillarga mo‘ljallangan strategiyasini tasdiqlash to‘g‘risida” PF–5853-son Farmoni",
        organing: "-"
     },
     {
        "№": 26,
        id: 25,
        name: "Millatlararo munosabatlar sohasida O‘zbekiston Respublikasi davlat siyosati konsepsiyasini amalga oshirishni taʼminlash bo‘yicha komissiya",
        description: "O‘zbekiston Respublikasi Prezidentining 2019 yil 15 noyabrdagi “Millatlararo munosabatlar sohasida O‘zbekiston Respublikasi davlat siyosati konsepsiyasini tasdiqlash to‘g‘risida”gi PF–5876-son Farmoni",
        organing: "-"
     },
     {
        "№": 27,
        id: 26,
        name: "Inflyatsiya darajasini pasaytirish va narxlar barqarorligini taʼminlash borasidagi chora-tadbirlarni muvofiqlashtirish bo‘yicha komissiya",
        description: "O‘zbekiston Respublikasi Prezidentining 2019 yil 18 noyabrdagi “Inflyatsion targetlash rejimiga bosqichma-bosqich o‘tish orqali pul-kredit siyosatini takomillashtirish to‘g‘risida”gi PF–5877-son Farmoni",
        organing: "-"
     },
     {
        "№": 28,
        id: 27,
        name: "Bozor tamoyillari asosida uy-joy qurilishi va sotilishining yangi tartibini joriy etish bo‘yicha respublika komissiyasi",
        description: "O‘zbekiston Respublikasi Prezidentining 2019 yil 28 noyabrdagi “Ipoteka krediti mexanizmlarini takomillashtirishga oid qo‘shimcha chora-tadbirlar to‘g‘risida”gi PF-5886-son Farmoni",
        organing: "-"
     },
     {
        "№": 29,
        id: 28,
        name: "Inqirozga qarshi kurashish respublika komissiyasi",
        description: "O‘zbekiston Respublikasi Prezidentining 2020 yil 19 martdagi “Koronavirus pandemiyasi va global inqiroz holatlarining iqtisodiyot tarmoqlariga salbiy taʼsirini yumshatish bo‘yicha birinchi navbatdagi chora-tadbirlari to‘g‘risida”gi PF–5969-son Farmoni",
        organing: "-"
     },
     {
        "№": 30,
        id: 29,
        name: "O‘zbekiston Respublikasi kiberxavfsizligini taʼminlash bo‘yicha idoralararo kengash",
        description: "O‘zbekiston Respublikasi Prezidentining 2020 yil 15 iyundagi “O‘zbekiston Respublikasining axborot tizimlari va resurslarini himoya qilish davlat tizimini joriy etish chora-tadbirlari to‘g‘risida” PF–6007-son Farmoni",
        organing: "-"
     },
     {
        "№": 31,
        id: 30,
        name: "O‘zbekiston Respublikasi korrupsiyaga qarshi kurashish milliy kengashi",
        description: "O‘zbekiston Respublikasi Prezidentining 2020 yil 29 iyundagi “O‘zbekiston Respublikasida korrupsiyaga qarshi kurashish tizimini takomillashtirish bo‘yicha qo‘shimcha chora-tadbirlar to‘g‘risida” PF–6013-son Farmoni",
        organing: "-"
     },
     {
        "№": 32,
        id: 31,
        name: "O‘zbekiston Respublikasida yoshlarga oid davlat siyosatini yanada rivojlantirish borasidagi chora-tadbirlarni amalga oshirish bo‘yicha muvofiqlashtiruvchi kengash",
        description: "O‘zbekiston Respublikasi Prezidentining 2020 yil 30 iyundagi “O‘zbekiston Respublikasida yoshlarga oid davlat siyosatini tubdan isloh qilish va yangi bosqichga olib chiqish chora-tadbirlari to‘g‘risida” PF–6017-son Farmoni",
        organing: "-"
     },
     {
        "№": 33,
        id: 32,
        name: "Tashqi savdo, investitsiyalar, mahalliy sanoatni rivojlantirish va texnik jihatdan tartibga solish masalalari bo‘yicha Hukumat komissiyasi",
        description: "O‘zbekiston Respublikasi Prezidentining 2020 yil 18 avgustdagi “Respublikada eksport va investitsiya salohiyatini yana-da rivojlantirishga oid qo‘shimcha chora-tadbirlar to‘g‘risida” PF-6042-son Farmoni",
        organing: "-"
     },
     {
        "№": 34,
        id: 33,
        name: "Yashirin iqtisodiyotni qisqartirish bo‘yicha maxsus komissiya",
        description: "O‘zbekiston Respublikasi Prezidentining 2020 yil 30 oktyabrdagi “Yashirin iqtisodiyotni qisqartirish va soliq organlari faoliyati samaradorligini oshirish bo‘yicha tashkiliy chora-tadbirlari to‘g‘risida” PF–6098-son Farmoni",
        organing: "-"
     },
     {
        "№": 35,
        id: 34,
        name: "“Termiz” erkin iqtisodiy zonasi, “Termiz xalqaro savdo markazi” erkin savdo zonasi va “Sergeli industrial parki” kichik sanoat zonasi faoliyatini rivojlantirish bo‘yicha ishchi guruhi",
        description: "O‘zbekiston Respublikasi Prezidentining 2020 yil 12 noyabrdagi “Surxondaryo viloyati va Toshkent shahrida maxsus iqtisodiy va kichik sanoat zonalari faoliyatini yanada rivojlantirish chora-tadbirlari to‘g‘risida”gi  PF–6109-son Farmoni",
        organing: "-"
     },
     {
        "№": 36,
        id: 35,
        name: "Sud hujjatlari va boshqa organlar hujjatlarini ijro etish samaradorligini oshirish bo‘yicha Respublika kengashi",
        description: "O‘zbekiston Respublikasi Prezidentining 2020 yil 24 noyabrdagi “Sud hujjatlari va boshqa organlar hujjatlarini ijro etish tizimini yanada takomillashtirish chora-tadbirlari to‘g‘risida”gi PF–6118-son Farmoni",
        organing: "-"
     },
     {
        "№": 37,
        id: 36,
        name: "Transport-logistika masalalarini tezkor ko‘rib chiqish bo‘yicha idoralararo komissiya",
        description: "O‘zbekiston Respublikasi Prezidentining 2021 yil 9 fevraldagi “O‘zbekiston Respublikasida ichki va ziyorat turizmini yana-da rivojlantirish chora-tadbirlari to‘g‘risida”gi PF–6165-son Farmoni",
        organing: "-"
     },
     {
        "№": 38,
        id: 37,
        name: "O‘zbekiston Respublikasi Moliya vazirligi huzuridagi Qishloq xo‘jaligini davlat tomonidan qo‘llab-quvvatlash jamg‘armasi kengashi",
        description: "O‘zbekiston Respublikasi Prezidentining 2021 yil 26 fevraldagi “O‘zbekiston Respublikasi Moliya vazirligi huzuridagi Qishloq xo‘jaligini davlat tomonidan qo‘llab-quvvatlash jamg‘armasi faoliyatini tubdan takomillashtirish to‘g‘risida”gi PF–6179-son Farmoni",
        organing: "-"
     },
     {
        "№": 39,
        id: 38,
        name: "Turizm, sport, madaniy meros, transport logistikasi va xizmatlar sohalarida davlat-xususiy sheriklik masalalari bo‘yicha muvofiqlashtiruvchi kengash",
        description: "O‘zbekiston Respublikasi Prezidentining 2021 yil 6 apreldagi “Turizm, sport va madaniy meros sohalarida davlat boshqaruvi tizimini yanada takomillashtirish chora-tadbirlari to‘g‘risida”gi PF–6199-son Farmoni",
        organing: "-"
     },
     {
        "№": 40,
        id: 39,
        name: "“Zomin” turistik-rekreatsion zonasi maʼmuriy kengashi",
        description: "O‘zbekiston Respublikasi Prezidentining 2021 yil 6 apreldagi “Zomin” turistik-rekreatsion zonasini va halqaro umummavsumiy kurortni tashkil etish chora-tadbirlari to‘g‘risida”gi PF–6201-son Farmoni",
        organing: "-"
     },
     {
        "№": 41,
        id: 40,
        name: "Milliy kinematografiya, kinokompaniya va kinoteatrlar tarmog‘ini yanada rivojlantirishga ko‘maklashish bo‘yicha respublika komissiyasi",
        description: "O‘zbekiston Respublikasi Prezidentining 2021 yil 7 apreldagi “Kino sanʼati va sanoatini yangi bosqichga olib chiqish, sohani davlat tomonidan qo‘llab-quvvatlash tizimini yanada takomillashtirish to‘g‘risida”gi PF–6202-son Farmoni",
        organing: "-"
     },
     {
        "№": 42,
        id: 41,
        name: "Yer to‘g‘risidagi qonun buzilishi holatlarini barvaqt aniqlash va ularning oldini olishga qaratilgan faoliyatni muvofiqlashtirish bo‘yicha Respublika kengashi",
        description: "O‘zbekiston Respublikasi Prezidentining 2021 yil 8 iyundagi “Yer munosabatlarida tenglik va shaffoflikni taʼminlash, yerga bo‘lgan huquqlarni ishonchli himoya qilish va ularni bozor aktiviga aylantirish chora-tadbirlari to‘g‘risida”gi PF–6243-son Farmoni",
        organing: "-"
     },
     {
        "№": 43,
        id: 42,
        name: "Aholi va tadbirkorlik subyektlariga davlat xizmatlarini ko‘rsatish tizimini muvofiqlashtirish bo‘yicha Respublika ishchi komissiyasi",
        description: "O‘zbekiston Respublikasi Prezidentining 2021 yil 24 iyuldagi “Davlat xizmatlari ko‘rsatish infratuzilmasini takomillashtirish va aholining davlat xizmatlaridan foydalanish imkoniyatlarini kengaytirish chora-tadbirlari to‘g‘risida” PF–6269-son Farmoni",
        organing: "-"
     },
     {
        "№": 44,
        id: 43,
        name: "Bolalar masalalari bo‘yicha milliy komissiya",
        description: "O‘zbekiston Respublikasi Prezidentining 2021 yil 9 avgustdagi “Bola huquqlarining kafolatlarini taʼminlash tizimini yana-da takomillashtirish chora-tadbirlari to‘g‘risida”gi PF–6275-son Farmoni",
        organing: "-"
     },
     {
        "№": 45,
        id: 44,
        name: "Farmatsevtika sanoatini rivojlantirish bo‘yicha komissiya",
        description: "O‘zbekiston Respublikasi Prezidentining 2022 yil 21 yanvardagi  “2022-2026 yillarda respublikaning farmatsevtika tarmog‘ini jadal rivojlantirishga oid qo‘shimcha chora-tadbirlar to‘g‘risida”gi PF-55-son Farmoni",
        organing: "-"
     },
     {
        "№": 46,
        id: 45,
        name: "2022–2026 yillarga mo‘ljallangan Yangi O‘zbekistonning taraqqiyot strategiyasini amalga oshirish bo‘yicha respublika komissiyasi",
        description: "O‘zbekiston Respublikasi Prezidentining 2022 yil 28 yanvardagi  “2022 – 2026 yillarga mo‘ljallangan Yangi O‘zbekistonning taraqqiyot strategiyasi to‘g‘risida” PF–60-son Farmoni",
        organing: "-"
     },
     {
        "№": 47,
        id: 46,
        name: "2022–2026 yillarga mo‘ljallangan Yangi O‘zbekistonning taraqqiyot strategiyasini amalga oshirish bo‘yicha respublika komissiyasining ishchi guruhi",
        description: "O‘zbekiston Respublikasi Prezidentining 2022 yil 28 yanvardagi  “2022 – 2026 yillarga mo‘ljallangan Yangi O‘zbekistonning taraqqiyot strategiyasi to‘g‘risida” PF–60-son Farmoni",
        organing: "-"
     },
     {
        "№": 48,
        id: 47,
        name: "Tadbirkorlik muhitini yaxshilash, xususiy sektorni rivojlantirish, davlat ishtirokidagi korxonalarning faoliyatini isloh qilish va xususiylashtirishga tayyorlash jarayonlarini tashkil etish bo‘yicha muvofiqlashtiruvchi ishchi guruh",
        description: "O‘zbekiston Respublikasi Prezidentining 2022 yil 8 apreldagi “Tadbirkorlik muhitini yaxshilash va xususiy sektorni rivojlantirish orqali barqaror iqtisodiy o‘sish uchun shart-sharoitlar yaratish borasidagi navbatdagi islohotlar to‘g‘risida”gi PF–101-son Farmoni",
        organing: "-"
     },
     {
        "№": 49,
        id: 48,
        name: "Qishloq va suv xo‘jaligi sohasi uchun muhandis-texnik kadrlar tayyorlash bo‘yicha oliy va o‘rta maxsus, kasb-hunar taʼlimi muassasalarining muvofiqlashtiruvchi kengashi",
        description: "O‘zbekiston Respublikasi Prezidentining 2022 yil 8 avgustdagi “O‘zbekiston Respublikasi Prezidentining ayrim hujjatlariga o‘zgartirish va qo‘shimchalar kiritish to‘g‘risida”gi PF–183-son Farmoni",
        organing: "-"
     },
     {
        "№": 50,
        id: 49,
        name: "“Raqamli O‘zbekiston – 2030” strategiyasini amalga oshirish bo‘yicha muvofiqlashtirish komissiyasi",
        description: "O‘zbekiston Respublikasi Prezidentining 2022 yil 22 avgustdagi “O‘zbekiston Respublikasi Prezidentining ayrim hujjatlariga raqamli iqtisodiyot va elektron hukumatni rivojlantirishga qaratilgan o‘zgartirish va qo‘shimchalar kiritish, shuningdek, baʼzilarini o‘z kuchini yo‘qotgan deb hisoblash to‘g‘risida”gi PF–194-son Farmoni",
        organing: "-"
     },
     {
        "№": 51,
        id: 50,
        name: "Tadbirkorlik faoliyatini qo‘llab-quvvatlash davlat jamg‘armasini boshqarish bo‘yicha Kengash",
        description: "O‘zbekiston Respublikasi Prezidentining 2022 yil 9 iyundagi “Tadbirkorlik faoliyatini qo‘llab-quvvatlash davlat jamg‘armasi faoliyatini yana-da takomillashtirishga doir qo‘shimcha chora-tadbirlar to‘g‘risida”gi PF–150-son Farmoni",
        organing: "-"
     },
     {
        "№": 52,
        id: 51,
        name: "O‘zbekiston Respublikasi Qurolli Kuchlarini qurollantirish bo‘yicha davlat komissiyasi",
        description: "O‘zbekiston Respublikasi Prezidentining 2023 yil 31 yanvardagi “O‘zbekiston Respublikasi Qurolli Kuchlarini qurollantirish va mudofaa sanoati sohasida davlat boshqaruvini takomillashtirish bo‘yicha chora-tadbirlar to‘g‘risida”gi PF-16-son Farmoni",
        organing: "-"
     },
     {
        "№": 53,
        id: 52,
        name: "O‘zbekiston Respublikasi Qurolli Kuchlarini qurollantirish vazifalarini amalga oshirish bo‘yicha tashkiliy-maʼmuriy guruh",
        description: "O‘zbekiston Respublikasi Prezidentining 2023 yil 31 yanvardagi “O‘zbekiston Respublikasi Qurolli Kuchlarini qurollantirish va mudofaa sanoati sohasida davlat boshqaruvini takomillashtirish bo‘yicha chora-tadbirlar to‘g‘risida”gi PF-16-son Farmoni",
        organing: "-"
     },
     {
        "№": 54,
        id: 53,
        name: "Yangi tahrirdagi O‘zbekiston Respublikasi Konstitutsiyasining ustuvor g‘oyalarini ro‘yobga chiqarish va normalarini hayotga tatbiq etish bo‘yicha Davlat komissiyasi",
        description: "O‘zbekiston Respublikasi Prezidentining 2023 yil 8 maydagi “Yangi tahrirdagi O‘zbekiston Respublikasi Konstitutsiyasini amalga oshirish bo‘yicha birinchi navbatdagi chora-tadbirlar to‘g‘risida”gi PF–67-son Farmoni",
        organing: "-"
     },
     {
        "№": 55,
        id: 54,
        name: "Yangi tahrirdagi O‘zbekiston Respublikasi Konstitutsiyasidagi suveren davlat prinsipini izchil amalga oshirish bo‘yicha ekspertlik kengashi",
        description: "O‘zbekiston Respublikasi Prezidentining 2023 yil 8 maydagi “Yangi tahrirdagi O‘zbekiston Respublikasi Konstitutsiyasini amalga oshirish bo‘yicha birinchi navbatdagi chora-tadbirlar to‘g‘risida”gi PF–67-son Farmoni",
        organing: "-"
     },
     {
        "№": 56,
        id: 55,
        name: "Yangi tahrirdagi O‘zbekiston Respublikasi Konstitutsiyasidagi demokratik davlat prinsipini izchil amalga oshirish bo‘yicha ekspertlik kengashi",
        description: "O‘zbekiston Respublikasi Prezidentining 2023 yil 8 maydagi “Yangi tahrirdagi O‘zbekiston Respublikasi Konstitutsiyasini amalga oshirish bo‘yicha birinchi navbatdagi chora-tadbirlar to‘g‘risida”gi PF–67-son Farmoni",
        organing: "-"
     },
     {
        "№": 57,
        id: 56,
        name: "Yangi tahrirdagi O‘zbekiston Respublikasi Konstitutsiyasidagi huquqiy davlat prinsipini izchil amalga oshirish bo‘yicha ekspertlik kengashi",
        description: "O‘zbekiston Respublikasi Prezidentining 2023 yil 8 maydagi “Yangi tahrirdagi O‘zbekiston Respublikasi Konstitutsiyasini amalga oshirish bo‘yicha birinchi navbatdagi chora-tadbirlar to‘g‘risida”gi PF–67-son Farmoni",
        organing: "-"
     },
     {
        "№": 58,
        id: 57,
        name: "Yangi tahrirdagi O‘zbekiston Respublikasi Konstitutsiyasidagi ijtimoiy davlat prinsipini izchil amalga oshirish bo‘yicha ekspertlik kengashi",
        description: "O‘zbekiston Respublikasi Prezidentining 2023 yil 8 maydagi “Yangi tahrirdagi O‘zbekiston Respublikasi Konstitutsiyasini amalga oshirish bo‘yicha birinchi navbatdagi chora-tadbirlar to‘g‘risida”gi PF–67-son Farmoni",
        organing: "-"
     },
     {
        "№": 59,
        id: 58,
        name: "Yangi tahrirdagi O‘zbekiston Respublikasi Konstitutsiyasidagi dunyoviy davlat prinsipini izchil amalga oshirish bo‘yicha ekspertlik kengashi",
        description: "O‘zbekiston Respublikasi Prezidentining 2023 yil 8 maydagi “Yangi tahrirdagi O‘zbekiston Respublikasi Konstitutsiyasini amalga oshirish bo‘yicha birinchi navbatdagi chora-tadbirlar to‘g‘risida”gi PF–67-son Farmoni",
        organing: "-"
     },
     {
        "№": 60,
        id: 59,
        name: "O‘zbekiston fermer, dehqon xo‘jaliklari va tomorqa yer egalari kengashi huzuridagi Fermer, dehqon xo‘jaliklari va tomorqa yer egalarini qo‘llab-quvvatlash jamg‘armasi Vasiylik kengashi",
        description: "O‘zbekiston Respublikasi Prezidentining 2023 yil 10 iyundagi “Maʼmuriy islohotlar doirasida qishloq xo‘jaligi va oziq-ovqat sohasida davlat boshqaruvini samarali tashkil qilish chora-tadbirlari to‘g‘risida”gi PF-90-son Farmoni",
        organing: "-"
     },
     {
        "№": 61,
        id: 60,
        name: "O‘zbekiston Respublikasi tranzit salohiyatini rivojlantirish bo‘yicha idoralararo kengash",
        description: "O‘zbekiston Respublikasi Prezidentining 2023 yil 14 iyundagi “Maʼmuriy islohotlar doirasida transport sohasida davlat boshqaruvini samarali tashkil qilish chora-tadbirlari to‘g‘risida”gi PF-94-son Farmoni",
        organing: "-"
     },
     {
        "№": 62,
        id: 61,
        name: "Radiochastotalar bo‘yicha Respublika kengashi",
        description: "O‘zbekiston Respublikasi Prezidentining 2013 yil 22 oktyabrdagi PQ–2053-son “Radiochastota spektrini boshqarishni tashkil qilish va foydalanishni takomillashtirish to‘g‘risida”gi qarori",
        organing: "-"
     },
     {
        "№": 63,
        id: 62,
        name: "Bolalar musiqa va sanʼat maktablari faoliyatini yana-da takomillashtirish bo‘yicha 2016-2020 yillarga mo‘ljallangan Davlat dasturi bajarilishini muvofiqlashtiruvchi Respublika komissiyasi",
        description: "O‘zbekiston Respublikasi Prezidentining 2015 yil 20 noyabrdagi “Bolalar musiqa va sanʼat maktablari faoliyatini yana-da takomillashtirish bo‘yicha 2016-2020 yillarga mo‘ljallangan Davlat dasturi to‘g‘risida”gi PQ–2435-son qarori",
        organing: "-"
     },
     {
        "№": 64,
        id: 63,
        name: "Xalqaro meva-sabzavot yarmarkasini har yili tashkil etish va o‘tkazish bo‘yicha Tashkiliy qo‘mita",
        description: "O‘zbekiston Respublikasi Prezidentining 2016 yil 1 iyundagi “Halqaro meva-sabzavot yarmarkasini tashkil etish va uni har yili o‘tkazib turish chora-tadbirlari to‘g‘risida”gi PQ–2539-son qarori",
        organing: "-"
     },
     {
        "№": 65,
        id: 64,
        name: "2017-2021 yillarda shaharlar va qishloqlarda avtotransport xizmatlarini yana-da rivojlantirish dastur bajarilishining doimiy monitoringi olib borilishini taʼminlash bo‘yicha ishchi guruh",
        description: "O‘zbekiston Respublikasi Prezidentining 2017 yil 10 yanvardagi “Aholiga transport xizmati ko‘rsatish hamda shaharlar va qishloqlarda avtobuslarda yo‘lovchilar tashish tizimini yana-da takomillashtirish chora-tadbirlari to‘g‘risida”gi PQ–2724-son qarori",
        organing: "-"
     },
     {
        "№": 66,
        id: 65,
        name: "Orolbo‘yi mintaqasini rivojlantirish Davlat dasturining amalga oshirilishini muvofiqlashtirish hamda nazorat qilish bo‘yicha Respublika komissiyasi",
        description: "O‘zbekiston Respublikasi Prezidentining 2017 yil 18 yanvardagi “2017-2021 yillarda Orolbo‘yi mintaqasini rivojlantirish Davlat dasturi to‘g‘risida”gi PQ–2731-son qarori",
        organing: "-"
     },
     {
        "№": 67,
        id: 66,
        name: "2020 yil Tokio (Yaponiya) shahrida o‘tkaziladigan XXXII yozgi Olimpiya va XVI Paralimpiya o‘yinlariga O‘zbekiston sportchilarini sifatli tayyorlashni tashkil etish bo‘yicha Respublika komissiyasi",
        description: "O‘zbekiston Respublikasi Prezidentining 2017 yil 9 martdagi “2020 yil Tokio (Yaponiya)  shahrida o‘tkaziladigan XXXII yozgi Olimpiya va XVI Paralimpiya o‘yinlariga O‘zbekiston sportchilarini tayyorlash to‘g‘risida”gi PQ–2821-son qarori",
        organing: "-"
     },
     {
        "№": 68,
        id: 67,
        name: "Huquqbuzarliklar profilaktikasi va jinoyatchilikka qarshi kurashish bo‘yicha respublika idoralararo komissiyasi",
        description: "O‘zbekiston Respublikasi Prezidentining 2017 yil 14 martdagi “Huquqbuzarliklar profilaktikasi va jinoyatchilikka qarshi kurashish tizimini yana-da takomillashtirish chora-tadbirlari to‘g‘risida”gi PQ–2833-son qarori",
        organing: "-"
     },
     {
        "№": 69,
        id: 68,
        name: "2017-2019 yillar davrida dehqon bozorlarini rekonstruksiya qilish va ularning hududida zamonaviy savdo majmualari qurish dasturini amalga oshirish bo‘yicha Respublika komissiyasi",
        description: "O‘zbekiston Respublikasi Prezidentining 2017 yil 27 martdagi “2017–2019 yillar davrida dehqon bozorlarini rekonstruksiya qilish va ularning hududida zamonaviy savdo majmualari qurish dasturini tasdiqlash to‘g‘risida”gi  PQ–2853-son qarori",
        organing: "-"
     },
     {
        "№": 70,
        id: 69,
        name: "Ko‘p xonadonli uy-joy fondini taʼmirlash, obodonlashtirish va undan foydalanish sharoitlarini yaxshilash dasturi amalga oshirilishini muvofiqlashtirish hamda monitoring qilish bo‘yicha Respublika komissiyasi",
        description: "O‘zbekiston Respublikasi Prezidentining 2017 yil 24 apreldagi “2017-2021 yillarda ko‘p xonadonli uy-joy fondini saqlash va undan foydalanish tizimini yana-da takomillashtirish chora-tadbirlari to‘g‘risida”gi PQ–2922-son qarori",
        organing: "-"
     },
     {
        "№": 71,
        id: 70,
        name: "O‘zbekiston Respublikasi mineral-xom ashyo bazasini rivojlantirish strategiyasi amalga oshirilishini muvofiqlashtirish bo‘yicha ishchi guruh",
        description: "O‘zbekiston Respublikasi Prezidentining 2017 yil 4 maydagi “2017-2021 yillarda yer osti suvlari zaxiralaridan oqilona foydalanishni nazorat qilish va hisobga olishni tartibga solish chora-tadbirlari to‘g‘risida”gi PQ–2954-son qarori",
        organing: "-"
     },
     {
        "№": 72,
        id: 71,
        name: "Mahalliy ijro hokimiyati va iqtisodiy kompleksning hududiy organlari rahbarlarini aholi bandligini oshirish uchun moddiy rag‘batlantirish va shaxsiy masʼuliyatini kuchaytirish chora-tadbirlarini muvofiqlashtirish bo‘yicha komissiya",
        description: "O‘zbekiston Respublikasi Prezidentining 2017 yil 6 maydagi “Aholi bandligini taʼminlash bo‘yicha olib borilayotgan ishlar natijadorligi va samaradorligini oshirishda mahalliy ijro hokimiyati va iqtisodiy kompleksning hududiy organlari rahbarlarining shaxsiy masʼuliyatini oshirish chora-tadbirlari to‘g‘risida”gi PQ–2960-son qarori",
        organing: "-"
     },
     {
        "№": 73,
        id: 72,
        name: "Hududlarni ijtimoiy-iqtisodiy rivojlantirish, iqtisodiyot tarmoqlari va ijtimoiy soha ahvolini o‘rganishning yangi mexanizmlarini joriy etish bo‘yicha komissiya",
        description: "O‘zbekiston Respublikasi Prezidentining 2017 yil 8 avgustdagi “Hududlarning jadal ijtimoiy-iqtisodiy rivojlanishini taʼminlashga doir ustuvor chora-tadbirlar to‘g‘risida”gi PQ–3182-son qarori",
        organing: "-"
     },
     {
        "№": 74,
        id: 73,
        name: "Seysmologiya, seysmik chidamli qurilish hamda O‘zbekiston Respublikasi aholisi va hududining seysmik xavfsizligi bo‘yicha idoralararo ilmiy-texnik kengash",
        description: "O‘zbekiston Respublikasi Prezidentining 2017 yil 9 avgustdagi “O‘zbekiston Respublikasi hududi va aholisining seysmik xavfsizligi, seysmik chidamli qurilish va seysmologiya sohasida ilmiy tadqiqotlar o‘tkazishni yana-da rivojlantirish chora-tadbirlari to‘g‘risida”gi PQ-3190-son qarori",
        organing: "-"
     },
     {
        "№": 75,
        id: 74,
        name: "O‘zbekiston Badiiy akademiyasi faoliyatini rivojlantirish va yana-da takomillashtirishga doir qo‘shimcha chora-tadbirlar dasturini amalga oshirish bo‘yicha Respublika komissiyasi",
        description: "O‘zbekiston Respublikasi Prezidentining 2017 yil 16 avgustdagi “O‘zbekiston Badiiy akademiyasi faoliyatini rivojlantirish va yana-da takomillashtirishga doir qo‘shimcha chora-tadbirlar to‘g‘risida”gi  PQ–3219-son qarori",
        organing: "-"
     },
     {
        "№": 76,
        id: 75,
        name: "Iqtisodiyot asosiy tarmoqlari korxonalarini moliyaviy sog‘lomlashtirish va davlat tomonidan ko‘llab-quvvatlash bo‘yicha Hukumat komissiyasi",
        description: "O‘zbekiston Respublikasi Prezidentining 2017 yil 4 sentyabrdagi “Valyuta bozorini liberallashtirish sharoitida iqtisodiyotning bazaviy tarmoqlarini davlat tomonidan qo‘llab-quvvatlashning birinchi galdagi chora-tadbirlari to‘g‘risida”gi PQ–3253-son qarori",
        organing: "-"
     },
     {
        "№": 77,
        id: 76,
        name: "“O‘zbekiston asalarichilari” uyushmasini tashkil etish bilan bog‘liq tashkiliy chora-tadbirlarni muvofiqlashtirish va uning kelgusi faoliyatini yo‘lga qo‘yishga ko‘maklashish bo‘yicha ishchi guruh",
        description: "O‘zbekiston Respublikasi Prezidentining 2017 yil 16 oktyabrdagi “Respublikamizda asalarichilik tarmog‘ini yana-da rivojlantirish chora-tadbirlar to‘g‘risida”gi PQ–3327-son qarori",
        organing: "-"
     },
     {
        "№": 78,
        id: 77,
        name: "“O‘zbekzargarsanoati” uyushmasini tashkil etish va zargarlik tarmog‘ini rivojlantirish bilan bog‘liq tashkiliy chora-tadbirlarni amalga oshirishni muvofiqlashtirish va amaliy yordam ko‘rsatish ishchi guruhi",
        description: "O‘zbekiston Respublikasi Prezidentining 2017 yil 15 noyabrdagi “2018-2020 yillarda O‘zbekiston Respublikasi zargarlik sanoatini yana-da rivojlantirish chora-tadbirlari to‘g‘risida”gi PQ–3388-son qarori",
        organing: "-"
     },
     {
        "№": 79,
        id: 78,
        name: "O‘zbek milliy maqom sanʼatini yana-da rivojlantirishga doir chora-tadbirlar dasturini amalga oshirish bo‘yicha Respublika komissiyasi",
        description: "O‘zbekiston Respublikasi Prezidentining 2017 yil 17 noyabrdagi “O‘zbek milliy maqom sanʼatini yana-da rivojlantirish chora-tadbirlari to‘g‘risida”gi PQ–3391-son qarori",
        organing: "-"
     },
     {
        "№": 80,
        id: 79,
        name: "2018-2022 yillarda transport infratuzilmasini takomillashtirish va yuk tashishning tashqi savdo yo‘nalishlarini diversifikatsiyalash bo‘yicha Kompleks dasturini amalga oshirilishini doimiy monitoringini taʼminlovchi ishchi guruh",
        description: "O‘zbekiston Respublikasi Prezidentining 2017 yil 2 dekabrdagi “2018–2022 yillarda transport infratuzilmasini takomillashtirish va yuk tashishning tashqi savdo yo‘nalishlarini diversifikatsiyalash chora-tadbirlari to‘g‘risida”gi PQ–3422-son qarori",
        organing: "-"
     },
     {
        "№": 81,
        id: 80,
        name: "Uzumning texnik navlarini yetishtirish bo‘yicha mavjud uzumzorlar inventarizatsiyasini o‘tkazish va vinochilik sohasini yanada rivojlantirish bo‘yicha takliflarni tayyorlash bo‘yicha Maxsus komissiya",
        description: "O‘zbekiston Respublikasi Prezidentining 2018 yil 28 fevraldagi “Vinochilik sohasini va alkogolli mahsulotlarni realizatsiya qilishni tubdan takomillashtirish chora-tadbirlari to‘g‘risida”gi PQ–3573-son qarori",
        organing: "-"
     },
     {
        "№": 82,
        id: 81,
        name: "Uyushma faoliyatini tashkil etish bilan bog‘liq chora-tadbirlarni muvofiqlashtirish va ularni amalga oshirishga ko‘maklashish bo‘yicha ishchi guruh",
        description: "O‘zbekiston Respublikasi Prezidentining 2018 yil 6 martdagi “O‘zbekiston Respublikasida limonchilik sohasini yana-da rivojlantirish chora-tadbirlari to‘g‘risida”gi PQ–3586-son qarori",
        organing: "-"
     },
     {
        "№": 83,
        id: 82,
        name: "O‘zbekiston Respublikasi Prezidenti huzuridagi Fuqarolik jamiyatini rivojlantirish bo‘yicha maslahat kengashi",
        description: "O‘zbekiston Respublikasi Prezidentining 2018 yil 4 maydagi “Mamlakatni demokratik yangilash jarayonida fuqarolik jamiyati institutlarining rolini tubdan oshirish chora-tadbirlari to‘g‘risida”gi PF–5430-son qarori",
        organing: "-"
     },
     {
        "№": 84,
        id: 83,
        name: "O‘zbekiston Respublikasining suveren kredit reytingini olish, suveren va korporativ (bank) obligatsiyalarni chiqarish va joylashtirish jarayonini muvofiqlashtirish idoralararo ishchi guruh",
        description: "O‘zbekiston Respublikasi Prezidentining 2018 yil 21 iyuldagi “Tashqi moliyalashtirish manbalarini diversifikatsiyalash bo‘yicha qo‘shimcha chora-tadbirlar to‘g‘risida”gi PQ–3877-son qarori",
        organing: "-"
     },
     {
        "№": 85,
        id: 84,
        name: "O‘zbekiston Respublikasi Qurolli Kuchlari tizimida maʼnaviy-maʼrifiy ishlar samaradorligini oshirish konsepsiyasini amalga oshirish bo‘yicha respublika idoralararo komissiya",
        description: "O‘zbekiston Respublikasi Prezidentining 2018 yil 4 avgustdagi  PQ–3898-son “O‘zbekiston Respublikasi Qurolli Kuchlari harbiy xizmatchilarining maʼnaviy-maʼrifiy saviyasini oshirish tizimini tubdan takomillashtirish to‘g‘risida”gi qarori",
        organing: "-"
     },
     {
        "№": 86,
        id: 85,
        name: "Jinoiy faoliyatdan olingan daromadlarni legallashtirishga, terrorizmni moliyalashtirishga va ommaviy qirg‘in qurolini tarqatishni moliyalashtirishga qarshi kurashish bo‘yicha idoralararo komissiyasi",
        description: "O‘zbekiston Respublikasi Prezidentining 2018 yil 20 sentyabrdagi “Jinoiy faoliyatdan olingan daromadlarni legallashtirishga, terrorizmni moliyalashtirishga va ommaviy qirg‘in qurolini tarqatishni moliyalashtirishga qarshi kurash bo‘yicha idoralararo komissiyani tashkil etish to‘g‘risida”gi  PQ–3947-son qarori",
        organing: "-"
     },
     {
        "№": 87,
        id: 86,
        name: "Elektr energetikasi tarmog‘ini isloh qilish bo‘yicha komissiya",
        description: "O‘zbekiston Republikasi Prezidentining 2018 yil 23 oktyabrdagi “Aholi  va iqtisodiyotni energiya resurslari bilan barqaror taʼminlash, neft-gaz tarmog‘ini moliyaviy sog‘lomlashtirish va uning boshqaruv tizimini takomillashtirish chora-tadbirlari to‘g‘risida”gi PQ-3981-son qarori",
        organing: "-"
     },
     {
        "№": 88,
        id: 87,
        name: "Xorijda istiqomat qilayotgan vatandoshlar bilan hamkorlik sohasida O‘zbekiston Respublikasi davlat siyosati konsepsiyasi va Xorijda istiqomat qilayotgan vatandoshlar bilan hamkorlikni rivojlantirish bo‘yicha birinchi navbatdagi chora-tadbirlar dasturini amalga oshirish bo‘yicha Komissiya",
        description: "O‘zbekiston Respublikasi Prezidentining 2018 yil 25 oktyabrdagi “Xorijda istiqomat qilayotgan vatandoshlar bilan hamkorlik sohasida O‘zbekiston Respublikasi davlat siyosatini yana-da takomillashtirish chora-tadbirlari to‘g‘risida”gi PQ–3982-son qarori",
        organing: "-"
     },
     {
        "№": 89,
        id: 88,
        name: "Jizzax viloyatida zamonaviy agroklasterni tashkil etish loyihasini amalga oshirishda har tomonlama ko‘maklashish bo‘yicha ishchi komissiya",
        description: "O‘zbekiston Respublikasi Prezidentining 2018 yil 9 noyabrdagi “Jizzax viloyatida zamonaviy agroklasterni tashkil etish chora-tadbirlari to‘g‘risida”gi PQ–4009-son qarori",
        organing: "-"
     },
     {
        "№": 90,
        id: 89,
        name: "Madaniyat ob’yektlari va muassasalarida zamonaviy axborot texnologiyalarini tatbiq etish bo‘yicha ishchi guruh",
        description: "O‘zbekiston Respublikasi Prezidentining 2019 yil 5 yanvardagi “Turizm tarmog‘ini jadal rivojlantirishga oid chora-tadbirlar to‘g‘risida”gi PQ–4095-son qarori",
        organing: "-"
     },
     {
        "№": 91,
        id: 90,
        name: "Prezident maktablari faoliyatini tashkil etish bo‘yicha komissiya",
        description: "O‘zbekiston Respublikasi Prezidentining 2019 yil 20 fevraldagi “Prezident maktablari faoliyatini tashkil etish chora-tadbirlari to‘g‘risida”gi PQ–4199-son qarori",
        organing: "-"
     },
     {
        "№": 92,
        id: 91,
        name: "Tashqi iqtisodiy faoliyat jarayonlarini takomillashtirish chora-tadbirlarini amalga oshirish bo‘yicha ishchi guruh",
        description: "O‘zbekiston Respublikasi Prezidentining 2019 yil 23 apreldagi “Tashqi iqtisodiy faoliyatni amalga oshirishda maʼmuriy tartib-taomillarni yana-da takomillashtirish chora-tadbirlari to‘g‘risida”gi PQ–4297-son qarori",
        organing: "-"
     },
     {
        "№": 93,
        id: 92,
        name: "Mavjud ishlab chiqarishlarni kengaytirish va sanoat mahsulotlarining yangi turlarini ishlab chiqarishni o‘zlashtirish bo‘yicha komissiya",
        description: "O‘zbekiston Respublikasi Prezidentining 2019 yil 1 maydagi “Sanoat kooperatsiyasini yanada rivojlantirish va talab yuqori bo‘lgan mahsulotlar ishlab chiqarishni kengaytirish chora-tadbirlari to‘g‘risida”gi PQ–4302-son qarori",
        organing: "-"
     },
     {
        "№": 94,
        id: 93,
        name: "Respublika Baxshichilik sanʼati markazi huzuridagi Baxshichilik sanʼatini rivojlantirish jamg‘armasi Kengashi",
        description: "O‘zbekiston Respublikasi Prezidentining 2019 yil 14 maydagi “Baxshichilik sanʼatini yanada rivojlantirish chora-tadbirlari to‘g‘risida”gi PQ–4320-son qarori",
        organing: "-"
     },
     {
        "№": 95,
        id: 94,
        name: "2019-2021 yillarda respublika hududlarida yengil konstruksiyali qurilish materiallaridan quriladigan yoki bo‘sh turgan bino va inshootlar negizida tashkil etiladigan tikuv-trikotaj mahsulotlari ishlab chiqarishga mo‘ljallangan majmualardagi loyihalarni amalga oshirishda har tomonlama ko‘maklashish bo‘yicha ishchi guruhi",
        description: "O‘zbekiston Respublikasi Prezidentining 2019 yil 28 maydagi “Respublika hududlarida tikuv-trikotaj mahsulotlari ishlab chiqarishni tashkil etish va aholi bandligini taʼminlash chora-tadbirlari to‘g‘risida”gi PQ–4341-son qarori",
        organing: "-"
     },
     {
        "№": 96,
        id: 95,
        name: "Elektrotexnika sanoatini rivojlantirishga ko‘maklashish bo‘yicha ishchi guruhi",
        description: "O‘zbekiston Respublikasi Prezidentining 2019 yil 30 maydagi “Elektrotexnika sanoatini yana-da rivojlantirish uchun qulay shart-sharoitlar yaratish va tarmoqning investitsiyaviy hamda eksport salohiyatini oshirish bo‘yicha qo‘shimcha chora-tadbirlar to‘g‘risida”gi PQ–4348-son qarori",
        organing: "-"
     },
     {
        "№": 97,
        id: 96,
        name: "O‘zbekiston Respublikasining neft-gaz tarmog‘ini 2030 yilgacha rivojlantirish konsepsiyasini amalga oshirish bo‘yicha respublika ishchi komissiyasi",
        description: "O‘zbekiston Republikasi Prezidentining 2019 yil 9 iyuldagi “Aholi va iqtisodiyotni energiya resurslari bilan barqaror taʼminlash, neft-gaz tarmog‘ini moliyaviy sog‘lomlashtirish va uning boshqaruv tizimini takomillashtirish chora-tadbirlari to‘g‘risida”gi PQ-4388-son qarori",
        organing: "-"
     },
     {
        "№": 98,
        id: 97,
        name: "Qishloq xo‘jaligi sohasida fan, taʼlim va ishlab chiqarishni integratsiyalash bo‘yicha Muvofiqlashtiruvchi kengashi",
        description: "O‘zbekiston Respublikasi Prezidentining 2019 yil 19 avgustdagi “Toshkent davlat agrar universiteti faoliyatini yanada takomillashtirish chora-tadbirlari to‘g‘risida”gi PQ–4421-son qarori",
        organing: "-"
     },
     {
        "№": 99,
        id: 98,
        name: "Energiya samaradorligi va qayta tiklanuvchi energiya manbalarini rivojlantirish masalalari bo‘yicha Respublika komissiyasi",
        description: "O‘zbekiston Respublikasi Prezidentining 2019 yil 22 avgustdagi “Iqtisodiyot tarmoqlari va ijtimoiy sohaning energiya samaradorligini oshirish, energiya tejovchi texnologiyalarni joriy etish va qayta tiklanuvchi energiya manbalarini rivojlantirishning tezkor chora-tadbirlari to‘g‘risida”gi  PQ–4422-son qarori",
        organing: "-"
     },
     {
        "№": 100,
        id: 99,
        name: "Aholining reproduktiv salomatligini mustahkamlash, onalar va bolalar salomatligini muhofaza qilish kengashi",
        description: "O‘zbekiston Respublikasi Prezidentining 2019 yil 8 noyabrdagi “Reproduktiv yoshdagi ayollar, homiladorlar va bolalarga ko‘rsatiladigan tibbiy yordam sifatini oshirish va ko‘lamini yana-da kengaytirish to‘g‘risida”gi  PQ–4513-son qarori",
        organing: "-"
     },
     {
        "№": 101,
        id: 100,
        name: "O‘zbekiston Respublikasi Prezidenti huzuridagi Xorijiy investorlar kengashining O‘zbekiston tomoni",
        description: "O‘zbekiston Respublikasi Prezidentining 2019 yil  13 noyabrdagi  “O‘zbekiston Respublikasi Prezidenti huzurida Xorijiy investorlar kengashini tuzish va uning faoliyatini tashkil etish chora-tadbirlari to‘g‘risida”gi PQ–4519-son qarori",
        organing: "-"
     },
     {
        "№": 102,
        id: 101,
        name: "Belarus-O‘zbekiston qo‘shma tarmoqlararo amaliy texnik kvalifikatsiyalar instituti Vasiylik kengashi",
        description: "O‘zbekiston Respublikasi Prezidentining 2019 yil 20 noyabrdagi “Toshkent shahrida Belarus-O‘zbekiston qo‘shma tarmoqlararo amaliy texnik kvalifikatsiyalar institutini tashkil etish to‘g‘risida” PQ–4524-son qarori",
        organing: "-"
     },
     {
        "№": 103,
        id: 102,
        name: "Respublikaning issiqlik taʼminoti tizimini isloh qilishga ko‘maklashish bo‘yicha ishchi guruh",
        description: "O‘zbekiston Respublikasi Prezidentining 2019 yil 2 dekabrdagi “Issiqlik taʼminoti tizimini takomillashtirish va issiqlik taʼminoti korxonalarini moliyaviy sog‘lomlashtirishga oid qo‘shimcha chora-tadbirlar to‘g‘risida”gi PQ–4542-son qarori",
        organing: "-"
     },
     {
        "№": 104,
        id: 103,
        name: "Chorvachilik va uning tarmoqlarini rivojlantirish Respublika kengashi",
        description: "O‘zbekiston Respublikasi Prezidentining 2020 yil 29 yanvardagi “Chorvachilik tarmog‘ini davlat tomonidan qo‘llab-quvvatlashning qo‘shimcha chora-tadbirlari to‘g‘risida” PQ–4576-son qarori",
        organing: "-"
     },
     {
        "№": 105,
        id: 104,
        name: "“Navoiy KMK” DK ishchi xodimlarining mehnat faoliyati va ijtimoiy himoyasi masalalarini kompleks hal qilish bo‘yicha komissiya",
        description: "O‘zbekiston Respublikasi Prezidentining 2020 yil 6 martdagi “Navoiy kon-metallurgiya kombinati” davlat korxonasini isloh qilish chora-tadbirlari to‘g‘risida”gi PQ–4629-son qarori",
        organing: "-"
     },
     {
        "№": 106,
        id: 105,
        name: "G‘alla yetishtirish, xarid qilish va sotishga bozor tamoyillarini keng joriy etishni muvofiqlashtiruvchi Respublika kengashi",
        description: "O‘zbekiston Respublikasi Prezidentining 2020 yil 6 martdagi “G‘alla yetishtirish, xarid qilish va sotish bozor tamoyillarini keng joriy etish chora-tadbirlari to‘g‘risida”gi PK–4634-son qarori",
        organing: "-"
     },
     {
        "№": 107,
        id: 106,
        name: "Shaharsozlik faoliyati sohasida texnik jihatdan tartibga solishga doir normativ hujjatlarni isloh qilish bo‘yicha muvofiqlashtiruvchi kengash",
        description: "O‘zbekiston Respublikasi Prezidentining 2020 yil 13 martdagi “O‘zbekiston Respublikasining qurilish sohasidagi islohatlarni chuqurlashtirishga doir qo‘shimcha chora-tadbirlari to‘g‘risida”gi PK–5963-son qarori",
        organing: "-"
     },
     {
        "№": 108,
        id: 107,
        name: "Grantlar va uzoq muddatli imtiyozli moliyalashtirishni jalb qilish bo‘yicha muvofiqlashtiruvchi guruh",
        description: "O‘zbekiston Respublikasi Prezidentining 2020 yil 22 apreldagi “Koronavirus pandemiyasi davrida aholi, byudjet, bazaviy infratuzilma va tadbirkorlik subyektlarini qo‘llab-quvvatlash uchun tashqi yordam mablag‘larini jalb qilish chora-tadbirlari to‘g‘risida” PF–4691-son qarori",
        organing: "-"
     },
     {
        "№": 109,
        id: 108,
        name: "Respublika hududlarini qishloq xo‘jaligi mahsulotlari yetishtirishga ixtisoslashtirish bo‘yicha ishchi guruh",
        description: "O‘zbekiston Respublikasi Prezidentining 2020 yil 11 maydagi “Respublika hududlarini qishloq xo‘jaligi mahsulotlari yetishtirishga ixtisoslashtirish bo‘yicha qo‘shimcha chora-tadbirlar to‘g‘risida”gi PQ–4709-son qarori",
        organing: "-"
     },
     {
        "№": 110,
        id: 109,
        name: "O‘zbekiston Respublikasi Davlat statistika qo‘mitasi huzuridagi Statistika kengashi",
        description: "O‘zbekiston Respublikasi Prezidentining 2020 yil 3 avgustdagi “O‘zbekiston Respublikasining Milliy statistika tizimini yana-da takomillashtirish va rivojlantirish chora-tadbirlari to‘g‘risida”gi PQ–4796-son qarori",
        organing: "-"
     },
     {
        "№": 111,
        id: 110,
        name: "2020-2021 yillarda Farg‘ona viloyatining So‘x tumanini kompleks ijtimoiy-iqtisodiy rivojlantirish chora-tadbirlari dasturini amalga oshirish bo‘yicha Respublika ishchi guruhi",
        description: "O‘zbekiston Respublikasi Prezidentining 2020 yil 8 avgustdagi “2020-2021 yillarda Farg‘ona viloyatining So‘x tumanini kompleks ijtimoiy-iqtisodiy rivojlantirish chora-tadbirlari to‘g‘risida”gi PQ-4798-son qarori",
        organing: "-"
     },
     {
        "№": 112,
        id: 111,
        name: "Kambag‘al va ishsiz fuqarolarni kasb-hunarga o‘rgatish va ularning mehnat faolligini oshirish bo‘yicha respublika ishchi guruhi",
        description: "O‘zbekiston Respublikasi Prezidentining 2020 yil 11 avgustdagi “Kambag‘al va ishsiz fuqarolarni tadbirkorlikka jalb qilish, ularning mehnat faolligini oshirish va kasb-hunarga o‘qitishga qaratilgan hamda aholi bandligini taʼminlashga oid qo‘shimcha chora-tadbirlar to‘g‘risida” PQ-4804-son qarori",
        organing: "-"
     },
     {
        "№": 113,
        id: 112,
        name: "“O‘zbekiston Respublikasi qishloq xo‘jaligini modernizatsiya qilish” loyihasini amalga oshirish bo‘yicha ishchi guruhi",
        description: "O‘zbekiston Respublikasi Prezidentining 2020 yil 11 avgustdagi “Xalqaro tiklanish va taraqqiyot banki hamda xalqaro taraqqiyot uyushmasi ishtirokida “O‘zbekiston Respublikasi qishloq xo‘jaligini modernizatsiya qilish” loyihasini amalga oshirish chora-tadbirlari to‘g‘risida” PQ-4803-son qarori",
        organing: "-"
     },
     {
        "№": 114,
        id: 113,
        name: "Davlat kadastrlarini shakllantirish bo‘yicha idoralararo ishchi guruh",
        description: "O‘zbekiston Respublikasi Prezidentining 2020 yil 7 sentyabrdagi “O‘zbekiston Respublikasi Davlat soliq qo‘mitasi huzuridagi Kadastr agentligi faoliyatini tashkil etish chora-tadbirlari to‘g‘risida” PQ–4819-son qarori",
        organing: "-"
     },
     {
        "№": 115,
        id: 114,
        name: "Vazirlar Mahkamasi huzurida Kasalliklar profilaktikasi va jamoat salomatligi masalalari bo‘yicha Muvofiqlashtiruvchi komissiya",
        description: "O‘zbekiston Respublikasi Prezidentining 2020 yil 12 noyabrdagi “Tibbiy profilaktika ishlari samaradorligini yana-da oshirish orqali jamoat salomatligini taʼminlashga oid qo‘shimcha chora-tadbirlar to‘g‘risida”gi PQ–4891-son qarori",
        organing: "-"
     },
     {
        "№": 116,
        id: 115,
        name: "Vazirlar Mahkamasi huzuridagi Biologik xavfsizlik kengashi",
        description: "O‘zbekiston Respublikasi Prezidentining 2020 yil 25 noyabrdagi “Biotexnologiyalarni rivojlantirish va mamlakatning biologik xavfsizligini taʼminlash tizimini takomillashtirish bo‘yicha kompleks chora-tadbirlar to‘g‘risida”gi PQ–4899-son qarori",
        organing: "-"
     },
     {
        "№": 117,
        id: 116,
        name: "Akademik litseylar faoliyatini takomillashtirish bo‘yicha komissiya",
        description: "O‘zbekiston Respublikasi Prezidentining 2020 yil 3 dekabrdagi “Iqtidorli yoshlarni saralab olish tizimi va akademik litseylar faoliyatini takomillashtirish chora-tadbirlari to‘g‘risida”gi PQ–4910-son qarori",
        organing: "-"
     },
     {
        "№": 118,
        id: 117,
        name: "Maxsus iqtisodiy va kichik sanoat zonalari, hududlarni ijtimoiy-iqtisodiy rivojlantirishga turtki beradigan loyihalar hamda to‘g‘ridan-to‘g‘ri xorijiy va mahalliy investitsiyalar ishtirokidagi yirik ishlab chiqarish loyihalarini tashqi muhandislik-kommunikatsiya infratuzilmasi bilan taʼminlashga davlat byudjetidan mablag‘lar ajratilishining maqsadga muvofiqligini aniqlash bo‘yicha maxsus komissiya",
        description: "O‘zbekiston Respublikasi Prezidentining 2020 yil 18 dekabrdagi “Hududlarda muhandislik-kommunikatsiya infratuzilmasini yanada rivojlantirish chora-tadbirlari to‘g‘risida”gi PQ–4929-son qarori",
        organing: "-"
     },
     {
        "№": 119,
        id: 118,
        name: "O‘zbekistonda shaxmatni jadal rivojlantirish bo‘yicha respublika kengashi",
        description: "O‘zbekiston Respublikasi Prezidentining 2021 yil 14 yanvardagi “Shaxmatni yanada rivojlantirish va ommaviylashtirish hamda shaxmatchilarni tayyorlash tizimini takomillashtirish chora-tadbirlari to‘g‘risida”gi  PQ–4954-son qarori",
        organing: "-"
     },
     {
        "№": 120,
        id: 119,
        name: "Ilmiy klasterni tashkil etish bo‘yicha ishchi guruh",
        description: "O‘zbekiston Respublikasi Prezidentining 2021 yil 13 fevraldagi “Kimyo sanoati korxonalarini yana-da isloh qilish va moliyaviy sog‘lomlashtirish, yuqori qo‘shilgan qiymatli kimyoviy mahsulotlar ishlab chiqarishni rivojlantirish chora-tadbirlari to‘g‘risida”gi PQ–4992-son qarori",
        organing: "-"
     },
     {
        "№": 121,
        id: 120,
        name: "Ilm-fanni moliyalashtirish va innovatsiyalarni qo‘llab-quvvatlash jamg‘armasi kuzatuv kengashi",
        description: "O‘zbekiston Respublikasi Prezidentining 2021 yil 1 apreldagi “Ilm-fan sohasidagi davlat siyosati va innovatsion rivojlantirishdagi davlat boshqaruvini yanada takomillashtirish chora-tadbirlari to‘g‘risida” PQ–5047-son qarori",
        organing: "-"
     },
     {
        "№": 122,
        id: 121,
        name: "Qamrov darajasi past bo‘lgan tumanlarda maktabgacha taʼlim xizmatlarini rivojlantirish bo‘yicha komissiya",
        description: "O‘zbekiston Respublikasi Prezidentining 2021 yil 26 maydagi “Hududlarda maktabgacha taʼlim xizmatlarini kengaytirish bo‘yicha qo‘shimcha chora-tadbirlar to‘g‘risida”gi PQ–5125-son qarori",
        organing: "-"
     },
     {
        "№": 123,
        id: 122,
        name: "Mis sanoati klasterini tashkil etish va rivojlantirish bo‘yicha maxsus komissiya",
        description: "O‘zbekiston Respublikasi Prezidentining 2021 yil 24 iyundagi “Kon-metallurgiya sanoati va unga bog‘liq sohalarni rivojlantirish bo‘yicha qo‘shimcha chora-tadbirlar to‘g‘risida”gi PQ–5159-son qarori",
        organing: "-"
     },
     {
        "№": 124,
        id: 123,
        name: "“Yangi Andijon” shahrini barpo etish ishlarini tashkil etish va muvofiqlashtirish bo‘yicha respublika komissiyasi",
        description: "O‘zbekiston Respublikasi Prezidentining 2021 yil 12 iyuldagi “Yangi Andijon” shahrini barpo etish ishlarini samarali tashkil etishga doir chora-tadbirlar to‘g‘risida”gi PQ–5180-son qarori",
        organing: "-"
     },
     {
        "№": 125,
        id: 124,
        name: "Alkogol va tamaki mahsulotlarini qonunga xilof ravishda tayyorlash va ularning aylanmasiga qarshi kurashish Kengashi",
        description: "O‘zbekiston Respublikasi Prezidentining 2021 yil 28 iyuldagi “Uzumchilikni rivojlantirishda klaster tizimini joriy etish, sohaga ilg‘or texnologiyalarni jalb qilishni davlat tomonidan qo‘llab-quvvatlashning qo‘shimcha chora-tadbirlari to‘g‘risida”gi PQ–5200-son qarori",
        organing: "-"
     },
     {
        "№": 126,
        id: 125,
        name: "Birlashgan Millatlar Tashkiloti Bosh Assambleyasining  2021 yil 18 maydagi 75-sesiyasida “Orolbo‘yi mintaqasini ekologik innovatsiyalar va texnologiyalar hududi deb eʼlon qilish to‘g‘risida”gi maxsus rezolyutsiyasini amalga oshirish bo‘yicha Idoralararo komissiya",
        description: "O‘zbekiston Respublikasi Prezidentining 2021 yil 29 iyuldagi “Birlashgan Millatlar Tashkiloti Bosh Assambleyasining 2021 yil 18 maydagi “Orolbo‘yi mintaqasini ekologik innovatsiyalar va texnologiyalar hududi deb eʼlon qilish to‘g‘risida”gi maxsus rezolyutsiyasini amalga oshirish choralari to‘g‘risida”gi PQ–5202-son qarori",
        organing: "-"
     },
     {
        "№": 127,
        id: 126,
        name: "Orolbo‘yi mintaqasida siyosiy-huquqiy innovatsiyalar orqali tarkibiy va transformatsion o‘zgarishlar uchun qulay muhit yaratish hamda institutsional bazani rivojlantirish bo‘yicha ishchi guruhi",
        description: "O‘zbekiston Respublikasi Prezidentining 2021 yil 29 iyuldagi “Birlashgan Millatlar Tashkiloti Bosh Assambleyasining 2021 yil 18 maydagi “Orolbo‘yi mintaqasini ekologik innovatsiyalar va texnologiyalar hududi deb eʼlon qilish to‘g‘risida”gi maxsus rezolyutsiyasini amalga oshirish choralari to‘g‘risida”gi PQ–5202-son qarori",
        organing: "-"
     },
     {
        "№": 128,
        id: 127,
        name: "Orolbo‘yi mintaqasida ekologik barqarorlikni taʼminlash, tabiiy resurslardan oqilona foydalanishni raqamlashtirish va innovatsion yondashuvlarni amalga oshirish bo‘yicha ishchi guruhi",
        description: "O‘zbekiston Respublikasi Prezidentining 2021 yil 29 iyuldagi “Birlashgan Millatlar Tashkiloti Bosh Assambleyasining 2021 yil 18 maydagi “Orolbo‘yi mintaqasini ekologik innovatsiyalar va texnologiyalar hududi deb eʼlon qilish to‘g‘risida”gi maxsus rezolyutsiyasini amalga oshirish choralari to‘g‘risida”gi PQ–5202-son qarori",
        organing: "-"
     },
     {
        "№": 129,
        id: 128,
        name: "Orolbo‘yi mintaqasida ijtimoiy sohani rivojlantirish va aholini qo‘llab-quvvatlashning innovatsion texnologiyalari hamda mexanizmlarini joriy qilish bo‘yicha ishchi guruhi",
        description: "O‘zbekiston Respublikasi Prezidentining 2021 yil 29 iyuldagi “Birlashgan Millatlar Tashkiloti Bosh Assambleyasining 2021 yil 18 maydagi “Orolbo‘yi mintaqasini ekologik innovatsiyalar va texnologiyalar hududi deb eʼlon qilish to‘g‘risida”gi maxsus rezolyutsiyasini amalga oshirish choralari to‘g‘risida”gi PQ–5202-son qarori",
        organing: "-"
     },
     {
        "№": 130,
        id: 129,
        name: "Orolbo‘yi mintaqasida iqtisodiy va moliyaviy innovatsion mexanizmlardan keng foydalanish, iqtisodiyot tarmoqlarida resurslarni tejovchi, “yashil” va sirkulyar (chiqindisiz, regenerativ va samarali) zamonaviy innovatsion texnologiyalarni keng tatbiq qilish bo‘yicha ishchi guruhi",
        description: "O‘zbekiston Respublikasi Prezidentining 2021 yil 29 iyuldagi “Birlashgan Millatlar Tashkiloti Bosh Assambleyasining 2021 yil 18 maydagi “Orolbo‘yi mintaqasini ekologik innovatsiyalar va texnologiyalar hududi deb eʼlon qilish to‘g‘risida”gi maxsus rezolyutsiyasini amalga oshirish choralari to‘g‘risida”gi PQ–5202-son qarori",
        organing: "-"
     },
     {
        "№": 131,
        id: 130,
        name: "Orolbo‘yi mintaqasida ilm-fanni rivojlantirish, ilmiy tadqiqotlar o‘tkazish, intellektual mulk obyektlarining yaratilishi huquqiy himoyasini taʼminlash hamda ilmiy-innovatsion ishlanmalarni joriy qilishda mahalliy va xalqaro hamkorlikni qo‘llab-quvvatlash bo‘yicha ishchi guruhi",
        description: "O‘zbekiston Respublikasi Prezidentining 2021 yil 29 iyuldagi “Birlashgan Millatlar Tashkiloti Bosh Assambleyasining 2021 yil 18 maydagi “Orolbo‘yi mintaqasini ekologik innovatsiyalar va texnologiyalar hududi deb eʼlon qilish to‘g‘risida”gi maxsus rezolyutsiyasini amalga oshirish choralari to‘g‘risida”gi PQ–5202-son qarori",
        organing: "-"
     },
     {
        "№": 132,
        id: 131,
        name: "“Orolbo‘yi mintaqasini ekologik innovatsiyalar va texnologiyalar hududi deb eʼlon qilish to‘g‘risida”gi Maxsus rezolyutsiyada belgilangan vazifalarni amalga oshirish uchun xalqaro hamkorlikni rivojlantirish hamda xorijiy investitsiyalarni jalb etish bo‘yicha ishchi guruhi",
        description: "O‘zbekiston Respublikasi Prezidentining 2021 yil 29 iyuldagi “Birlashgan Millatlar Tashkiloti Bosh Assambleyasining 2021 yil 18 maydagi “Orolbo‘yi mintaqasini ekologik innovatsiyalar va texnologiyalar hududi deb eʼlon qilish to‘g‘risida”gi maxsus rezolyutsiyasini amalga oshirish choralari to‘g‘risida”gi PQ–5202-son qarori",
        organing: "-"
     },
     {
        "№": 133,
        id: 132,
        name: "Orolbo‘yi mintaqasida innovatsion loyihalarni moliyalashtirishning ichki manbalarini shakllantirish va muvofiqlashtirish bo‘yicha ishchi guruhi",
        description: "O‘zbekiston Respublikasi Prezidentining 2021 yil 29 iyuldagi “Birlashgan Millatlar Tashkiloti Bosh Assambleyasining 2021 yil 18 maydagi “Orolbo‘yi mintaqasini ekologik innovatsiyalar va texnologiyalar hududi deb eʼlon qilish to‘g‘risida”gi maxsus rezolyutsiyasini amalga oshirish choralari to‘g‘risida”gi PQ–5202-son qarori",
        organing: "-"
     },
     {
        "№": 134,
        id: 133,
        name: "Yilqichilik, ot sporti, zamonaviy beshkurash va polo sport turini rivojlantirishga ko‘maklashish bo‘yicha ishchi guruh",
        description: "O‘zbekiston Respublikasi Prezidentining 2021 yil 5 avgustdagi “O‘zbekistonda yilqichilik, ot sporti, zamonaviy beshkurash va poloni  2025 yilgacha rivojlantirish davlat dasturi to‘g‘risida”gi PQ–5211-son qarori",
        organing: "-"
     },
     {
        "№": 135,
        id: 134,
        name: "Yetim bolalar va ota-ona qaramog‘idan mahrum bo‘lgan bolalarni uy-joylar bilan taʼminlashni tashkil etish, uy joylarning adolatli taqsimlanishi va o‘z vaqtida ajratilishini nazorat qilish bo‘yicha Respublika komissiyasi",
        description: "O‘zbekiston Respublikasi Prezidentining 2021 yil 9 avgustdagi “Yetim bolalar va ota-ona qaramog‘idan mahrum bo‘lgan bolalarni davlat tomonidan qo‘llab-quvvatlashning yangi tizimini joriy etish chora-tadbirlari to‘g‘risida”gi PQ–5216-son qarori",
        organing: "-"
     },
     {
        "№": 136,
        id: 135,
        name: "Velosportni rivojlantirish bo‘yicha respublika kengashi",
        description: "O‘zbekiston Respublikasi Prezidentining 2021 yil 24 sentyabrdagi “Velosportni rivojlantirishga doir kompleks chora-tadbirlar to‘g‘risida”gi  PQ-5251-son qarori",
        organing: "-"
     },
     {
        "№": 137,
        id: 136,
        name: "2024 yil Parij shahrida (Fransiya) bo‘lib o‘tadigan XXXIII yozgi Olimpiya va XVII Paralimpiya o‘yinlariga O‘zbekiston sportchilarini tayyorlash bo‘yicha Respublika komissiyasi",
        description: "O‘zbekiston Respublikasi Prezidentining 2021 yil 5 noyabrdagi “2024 yil Parij shahrida (Fransiya) bo‘lib o‘tadigan XXXIII yozgi Olimpiya va XVII Paralimpiya o‘yinlariga O‘zbekiston sportchilarini kompleks tayyorlash to‘g‘risida” PQ–5281-son qarori",
        organing: "-"
     },
     {
        "№": 138,
        id: 137,
        name: "Hokim yordamchilari faoliyatini tashkil etish bo‘yicha respublika komissiyasi",
        description: "O‘zbekiston Respublikasi Prezidentining 2021 yil 3 dekabrdagi “Mahallada tadbirkorlikni rivojlantirish, aholi bandligini taʼminlash va kambag‘allikni qisqartirish masalalari bo‘yicha hokim yordamchilari faoliyatini tashkil etish chora-tadbirlari to‘g‘risida”gi PQ–31-son qarori",
        organing: "-"
     },
     {
        "№": 139,
        id: 138,
        name: "Davlat dasturlari ijro etilishini nazorat qilish va muvofiqlashtirish bo‘yicha respublika shtabi",
        description: "O‘zbekiston Respublikasi Prezidentining 2021 yil 10 dekabrdagi “O‘zbekiston Respublikasining ijtimoiy va ishlab chiqarish infratuzilmasini rivojlantirish dasturi hamda boshqa davlat dasturlari ijrosi ustidan nazoratni kuchaytirishga doir qo‘shimcha chora-tadbirlar to‘g‘risida”gi PQ-43-son qarori",
        organing: "-"
     },
     {
        "№": 140,
        id: 139,
        name: "Ijtimoiy va ishlab chiqarish infratuzilmasini rivojlantirish bo‘yicha Respublika komissiyasi",
        description: "O‘zbekiston Respublikasi Prezidentining 2022 yil 22 yanvardagi  “2022-2024 yillarda O‘zbekiston Respublikasining ijtimoiy va ishlab chiqarish infratuzilmasini rivojlantirish chora-tadbirlar to‘g‘risida”gi PQ–98-son qarori",
        organing: "-"
     },
     {
        "№": 141,
        id: 140,
        name: "Yo‘l harakati xavfsizligini taʼminlash bo‘yicha respublika maxsus komissiyasi",
        description: "O‘zbekiston Respublikasi Prezidentining 2022 yil 4 apreldagi “Avtomobil yo‘llarida inson xavfsizligini ishonchli taʼminlash va o‘lim holatlarini keskin kamayirish chora-tadbirlari to‘g‘risida”giPQ–190-son qarori",
        organing: "-"
     },
     {
        "№": 142,
        id: 141,
        name: "Qishloq xo‘jaligiga mo‘ljallangan va o‘rmon fondi yerlarining yer fondi toifalarini o‘zgartirish masalalarini ko‘rib chiqish bo‘yicha Hukumat komissiyasi",
        description: "O‘zbekiston Respublikasi Prezidentining 2022 yil 12 apreldagi “Muhandislik-kommunikatsiya tarmoqlari uchun yer uchastkalarini ajratish tartibini takomillashtirish chora-tadbirlari to‘g‘risida”gi PQ–204-son qarori",
        organing: "-"
     },
     {
        "№": 143,
        id: 142,
        name: "Paralimpiyachilarning matonat maktabi faoliyatini samarali yo‘lga qo‘yish va paralimpiya sport turlarini yana-da rivojlantirish bo‘yicha respublika kengashi",
        description: "O‘zbekiston Respublikasi Prezidentining 2022 yil 6 iyundagi “Paralimpiyachilarning matonat maktabi faoliyatini tashkil etish chora-tadbirlari to‘g‘risida”gi PQ–270-son qarori",
        organing: "-"
     },
     {
        "№": 144,
        id: 143,
        name: "Tez tibbiy yordam xizmatini isloh qilishga qaratilgan chora-tadbirlarni amalga oshirish bo‘yicha ishchi guruh",
        description: "O‘zbekiston Respublikasi Prezidentining 2022 yil 16 iyundagi “Aholiga tez tibbiy yordam ko‘rsatish tizimini takomillashtirish to‘g‘risida”gi PQ–283-son qarori",
        organing: "-"
     },
     {
        "№": 145,
        id: 144,
        name: "2026 yil Milan va Kortina-d‘Ampesso (Italiya)  shaharlarida bo‘lib o‘tadigan XXV qishki Olimpiya va XIV Paralimpiya o‘yinlariga O‘zbekiston sportchilarini tayyorlash bo‘yicha Respublika komissiyasi",
        description: "O‘zbekiston Respublikasi Prezidentining 2022 yil 1 iyuldagi “2026 yil Milan va Kortina-d‘Ampesso (Italiya) shaharlarida bo‘lib o‘tadigan XXV qishki Olimpiya va XIV Paralimpiya o‘yinlariga O‘zbekiston sportchilarini kompleks tayyorlash to‘g‘risida”gi PQ–303-son qarori",
        organing: "-"
     },
     {
        "№": 146,
        id: 145,
        name: "Neft va gaz sohasidagi taʼlim-ishlab chiqarish klasteri faoliyatini muvofiqlashtiruvchi kengash",
        description: "O‘zbekiston Respublikasi Prezidentining 2022 yil 7 iyuldagi “Neft va gaz sohasida taʼlim-ishlab chiqarish klasterini tashkil etish chora-tadbirlari to‘g‘risida”gi PQ–309-son qarori",
        organing: "-"
     },
     {
        "№": 147,
        id: 146,
        name: "“Navoiyuran” davlat korxonasini transformatsiya qilish va faoliyatini rivojlantirish chora-tadbirlari, shuningdek uran zaxiralarini, ularni qazib olishni va qayta ishlashni ko‘paytirish bo‘yicha maqsadli ko‘rsatkichlarni bajarilishini muvofiqlashtirish bo‘yicha ishchi guruhi",
        description: "O‘zbekiston Respublikasi Prezidentining 2022 yil 14 iyuldagi “2022–2030 yillarda “Navoiyuran” davlat korxonasida uranni qazib olish, qayta ishlash hajmlarini oshirish hamda uni transformatsiya qilish chora-tadbirlari to‘g‘risida” PQ–319-son qarori",
        organing: "-"
     },
     {
        "№": 148,
        id: 147,
        name: "O‘zbekiston Respublikasi davlat mustaqilligining o‘ttiz bir yillik bayramiga tayyorgarlik ko‘rish va uni yuqori saviyada o‘tkazish bo‘yicha respublika komissiyasi",
        description: "O‘zbekiston Respublikasi Prezidentining 2022 yil 1 avgustdagi “O‘zbekiston Respublikasi davlat mustaqilligining o‘ttiz bir yillik bayramiga tayyorgarlik ko‘rish va uni yuqori saviyada o‘tkazish to‘g‘risida”gi PQ-340-son qarori",
        organing: "-"
     },
     {
        "№": 149,
        id: 148,
        name: "Chiqindilar bilan bog‘liq ishlarni amalga oshirish tizimini muvofiqlashtirish va takomillashtirish bo‘yicha respublika komissiya",
        description: "O‘zbekiston Respublikasi Prezidentining 2022 yil 11 avgustdagi “Sanitar tozalash ishlarini tashkil etish va aholi punktlarida tozalikni taʼminlash bo‘yicha qo‘shimcha chora-tadbirlar to‘g‘risida”gi PQ–349-son qarori",
        organing: "-"
     },
     {
        "№": 150,
        id: 149,
        name: "2022-2023 yillarda mahallalar infratuzilmasini rivojlantirish bo‘yicha loyihalar dasturlari ijrosini muvofiqlashtirish yuzasidan Respublika ishchi guruhi",
        description: "O‘zbekiston Respublikasi Prezidentining 2022 yil 25 oktyabrdagi  “2022-2023 yillarda mahallalar infratuzilmasini yanada yaxshilash bo‘yicha qo‘shimcha chora-tadbirlar to‘g‘risida” PQ–408-son qarori",
        organing: "-"
     },
     {
        "№": 151,
        id: 150,
        name: "Kibersport turlarini yana-da rivojlantirish va keng ommalashtirish bo‘yicha idoralararo kengash",
        description: "O‘zbekiston Respublikasi Prezidentining 2022 yil 16 noyabrdagi “Kibersport turlarini yanada rivojlantirish va keng ommalashtirishga oid qo‘shimcha chora-tadbirlar to‘g‘risida”gi PQ–423-son qarori",
        organing: "-"
     },
     {
        "№": 152,
        id: 151,
        name: "Toshqin suvlari va sel oqimlarini xavf-xatarsiz o‘tkazib yuborishni taʼminlash, qor ko‘chishi va yer ko‘chkisi hodisalari xavfini kamaytirish bo‘yicha Hukumat komissiyasi",
        description: "O‘zbekiston Respublikasi Prezidentining 2022 yil 18 noyabrdagi “Aholini va hududlarni xavfli gidrometeorologik hodisalar hamda geologik jarayonlar bilan bog‘liq favqulodda vaziyatlardan muhofaza qilish chora-tadbirlari to‘g‘risida” PQ–426-son qarori",
        organing: "-"
     },
     {
        "№": 153,
        id: 152,
        name: "Bolalarning dam olishlarini tizimli tashkil etish va sog‘lomlashtirish masalalari bo‘yicha respublika komissiyasi",
        description: "O‘zbekiston Respublikasi Prezidentining 2022 yil 18 noyabrdagi “Bolalarning dam olishlarini tizimli tashkil etish va sog‘lomlashtirish ishlarini yana-da takomillashtirishga doir qo‘shimcha chora-tadbirlar to‘g‘risida”  PQ–427-son qarori",
        organing: "-"
     },
     {
        "№": 154,
        id: 153,
        name: "“Oromgohlarda taʼlim-tarbiyaviy ishlarni tashkil etish, ularni malakali pedagog va yetakchilar bilan taʼminlash” bo‘yicha ishchi guruh",
        description: "O‘zbekiston Respublikasi Prezidentining 2022 yil 18 noyabrdagi “Bolalarning dam olishlarini tizimli tashkil etish va sog‘lomlashtirish ishlarini yana-da takomillashtirishga doir qo‘shimcha chora-tadbirlar to‘g‘risida”  PQ–427-son qarori",
        organing: "-"
     },
     {
        "№": 155,
        id: 154,
        name: "“Bolalarga malakali tibbiy xizmat ko‘rsatish, oromgohlarda oziq-ovqat mahsulotlari sifatini, sanitariya-gigiyena talablariga rioya etilishini nazorat qilish” bo‘yicha ishchi guruh",
        description: "O‘zbekiston Respublikasi Prezidentining 2022 yil 18 noyabrdagi “Bolalarning dam olishlarini tizimli tashkil etish va sog‘lomlashtirish ishlarini yana-da takomillashtirishga doir qo‘shimcha chora-tadbirlar to‘g‘risida”  PQ–427-son qarori",
        organing: "-"
     },
     {
        "№": 156,
        id: 155,
        name: "“Bolalarni vatanparvarlik ruhida tarbiyalashga qaratilgan maʼnaviy-maʼrifiy tadbirlar va sport musobaqalarini o‘tkazish, ularga madaniy xizmat ko‘rsatishni tashkil etish” bo‘yicha ishchi guruh",
        description: "O‘zbekiston Respublikasi Prezidentining 2022 yil 18 noyabrdagi “Bolalarning dam olishlarini tizimli tashkil etish va sog‘lomlashtirish ishlarini yana-da takomillashtirishga doir qo‘shimcha chora-tadbirlar to‘g‘risida”  PQ–427-son qarori",
        organing: "-"
     },
     {
        "№": 157,
        id: 156,
        name: "“Oromgohlarda jamoat tartibini saqlash, aloqa, transport xizmati va harakat xavfsizligini taʼminlash, favqulodda vaziyatlar bo‘yicha profilaktik tadbirlar o‘tkazish” bo‘yicha ishchi guruh",
        description: "O‘zbekiston Respublikasi Prezidentining 2022 yil 18 noyabrdagi “Bolalarning dam olishlarini tizimli tashkil etish va sog‘lomlashtirish ishlarini yana-da takomillashtirishga doir qo‘shimcha chora-tadbirlar to‘g‘risida”  PQ–427-son qarori",
        organing: "-"
     },
     {
        "№": 158,
        id: 157,
        name: "“Oromgohlarda elektr energiyasi va tabiiy gaz taʼminoti uzluksizligini taʼminlash, ularga sifatli kommunal xizmat ko‘rsatish” bo‘yicha ishchi guruh",
        description: "O‘zbekiston Respublikasi Prezidentining 2022 yil 18 noyabrdagi “Bolalarning dam olishlarini tizimli tashkil etish va sog‘lomlashtirish ishlarini yana-da takomillashtirishga doir qo‘shimcha chora-tadbirlar to‘g‘risida”  PQ–427-son qarori",
        organing: "-"
     },
     {
        "№": 159,
        id: 158,
        name: "O‘zbekiston Respublikasida “yashil” iqtisodiyotga o‘tish choralarini muvofiqlashtirish bo‘yicha idoralararo kengash",
        description: "O‘zbekiston Respublikasi Prezidentining 2022 yil 2 dekabrdagi  “2030 yilgacha O‘zbekiston Respublikasining “yashil” iqtisodiyotga o‘tishiga qaratilgan islohotlar samaradorligini oshirish bo‘yicha chora-tadbirlar to‘g‘risida” PQ–436-son qarori",
        organing: "-"
     },
     {
        "№": 160,
        id: 159,
        name: "Пахта-тўқимачилик кластерлари фаолиятини мувофиқлаштириш республика комиссияси",
        description: "O‘zbekiston Respublikasi Prezidentining 2023 yil 26 yanvardagi “Paxta xom ashyosi yetishtiruvchilar faoliyatini yanada qo‘llab-quvvatlashning qo‘shimcha chora-tadbirlari to‘g‘risida”gi PQ–23-son qarori",
        organing: "-"
     },
     {
        "№": 161,
        id: 160,
        name: "Agrar sohada ilm-fan, taʼlim va ishlab chiqarishni integratsiyalash bo‘yicha muvofiqlashtiruvchi kengash",
        description: "O‘zbekiston Respublikasi Prezidentining 2023 yil 7 iyuldagi “Agrar sohada ilm-fan, taʼlim va ishlab chiqarishni integratsiya qilish orqali sifat va samaradorlikni oshirishning qo‘shimcha chora-tadbirlari to‘g‘risida”gi  PQ-216-son qarori",
        organing: "-"
     },
     {
        "№": 162,
        id: 161,
        name: "2015-2019 yillarda Guliston shahrida drenaj tizimini yanada takomillashtirish, meliorativ holatini yaxshilash va yer osti suv sathini kamaytirish, muhandislik-kommunikatsiya va ijtimoiy infratuzilmani rivojlantirish dasturini ishlab chiqish bo‘yicha Respublika komissiyasi",
        description: "O‘zbekiston Respublikasi Prezidentining 2015 yil 9 martdagi “2015-2019 yillarda Guliston shahrida drenaj tizimini yanada takomillashtirish, meliorativ holatini yaxshilash va yer osti suv sathini kamaytirish, muhandislik-kommunikatsiya va ijtimoiy infratuzilmani rivojlantirish dasturini ishlab chiqish to‘g‘risida”gi F–4421-son farmoyishi",
        organing: "-"
     },
     {
        "№": 163,
        id: 162,
        name: "Moliya, soliq va bojxona organlari faoliyatini tubdan takomillashtirishga yo‘naltirilgan takliflarni tanqidiy o‘rganish va tayyorlash bo‘yicha ishchi komissiya",
        description: "O‘zbekiston Respublikasi Prezidentining 2018 yil 16 yanvardagi “Moliya, soliq va bojxona organlari faoliyatini tanqidiy o‘rganilishini tashkil etish to‘g‘risida”gi F–5182-son farmoyishi",
        organing: "-"
     },
     {
        "№": 164,
        id: 163,
        name: "Qatag‘on qurbonlarining merosini yana-da chuqur o‘rganish va ular xotirasini abadiylashtirish ishlarini tashkil etish va muvofiqlashtirish bo‘yicha Respublika ishchi guruhi",
        description: "O‘zbekiston Respublikasi Prezidentining 2020 yil 8 oktyabrdagi “Qatag‘on qurbonlarining merosini yana-da chuqur o‘rganish va ular xotirasini abadiylashtirishga doir qo‘shimcha chora-tadbirlari to‘g‘risida” F–5598-son farmoyishi",
        organing: "-"
     },
     {
        "№": 165,
        id: 164,
        name: "Xalq taʼlimi tizimini isloh qilish bo‘yicha milliy kengash",
        description: "O‘zbekiston Respublikasi Prezidentining 2022 yil 3 fevraldagi “Xalq taʼlimi tizimini isloh qilish bo‘yicha qo‘shimcha chora-tadbirlar to‘g‘risida”gi  F–22-son farmoyishi",
        organing: "-"
     },
     {
        "№": 166,
        id: "-",
        name: "O‘zbekiston Respublikasi Vazirlar Mahkamasi hujjatlari bilan tuzilgan idoralararo kollegial organlar",
        description: "-",
        organing: "-"
     },
     {
        "№": 167,
        id: 165,
        name: "Narkotik moddalar ustidan nazorat qilish bo‘yicha O‘zbekiston Respublikasi Davlat komissiyasi",
        description: "Vazirlar Mahkamasining 1994 yil 30 apreldagi “Narkotik moddalar ustidan nazorat qilish bo‘yicha O‘zbekiston Respublikasi Davlat komissiyasini tashkil etish to‘g‘risida”gi 229-son qarori",
        organing: "-"
     },
     {
        "№": 168,
        id: 166,
        name: "Axborot faoliyati va maʼlumotlar uzatishni takomillashtirish va samaradorligini oshirish bo‘yicha idoralararo muvofiqlashtirish komissiyasi",
        description: "Vazirlar Mahkamasining 1998 yil 10 iyuldagi “Chastota spektridan foydalanish, teleradiodasturlarni shakllantirish va tarqatish hamda maʼlumotlar uzatish samaradorligini oshirish yuzasidan qo‘shimcha chora-tadbirlar to‘g‘risida”gi 293-son qarori",
        organing: "-"
     },
     {
        "№": 169,
        id: 167,
        name: "O‘zbekiston Respublikasi Vazirlar Mahkamasining Bakteriologik (biologik) va toksik qurol-yarog‘larni ishlab chiqish, tayyorlash va to‘plashni hamda ularni tugatish to‘g‘risidagi Konvensiyani amalga oshirish komissiyasi",
        description: "Vazirlar Mahkamasining 1998 yil 22 sentyabrdagi “O‘zbekiston Respublikasi Vazirlar Mahkamasining Bakteriologik (biologik) va toksik qurol-yarog‘larni ishlab chiqish, tayyorlash va to‘plashni man etish hamda ularni tugatish to‘g‘risidagi konvensiyani amalga oshirish komissiyasini tashkil etish haqida”gi 405-son qarori",
        organing: "-"
     },
     {
        "№": 170,
        id: 168,
        name: "O‘zbekiston Respublikasi taʼlim muassasalariga qabul qilish bo‘yicha Davlat komissiyasi",
        description: "Vazirlar Mahkamasining 2003 yil 30 oktyabrdagi “O‘zbekiston Respublikasi taʼlim muassasalariga qabul qilish bo‘yicha Davlat komissiyasining tarkibi to‘g‘risida”gi 475-son qarori",
        organing: "-"
     },
     {
        "№": 171,
        id: 169,
        name: "Yarmarka savdosi bo‘yicha tashkiliy qo‘mita",
        description: "Vazirlar Mahkamasining 2009 yil 28 yanvardagi “Yengil sanoat mahsulotlari yarmarka savdosini tashkil etish bo‘yicha chora-tadbirlar to‘g‘risida”gi 25-son qarori",
        organing: "-"
     },
     {
        "№": 172,
        id: 170,
        name: "O‘zbekiston Respublikasi Vazirlar Mahkamasi huzuridagi Transport va transport kommunikatsiyalari masalalari bo‘yicha maxsus komissiya",
        description: "Vazirlar Mahkamasining 2009 yil 6 martdagi “O‘zbekiston Respublikasi Vazirlar Mahkamasi huzurida Transport va transport kommunikatsiyalari masalalari bo‘yicha maxsus komissiya tashkil etish to‘g‘risida”gi 57-son qarori",
        organing: "-"
     },
     {
        "№": 173,
        id: 171,
        name: "Qashqadaryo viloyatining Shahrisabz shahrida obyektlarni qurish va rekonstruksiya qilishga doir kompleks chora-tadbirlarini amalga oshirish hamda ularning bajarilishi monitoringini olib borish bo‘yicha ishchi komissiya",
        description: "Vazirlar Mahkamasining 2014 yil 20 fevraldagi “Qashqadaryo viloyatining Shahrisabz shahrida obyektlarni qurish va rekonstruksiya qilishga doir kompleks chora-tadbirlar dasturi to‘g‘risida”gi 31-son qarori",
        organing: "-"
     },
     {
        "№": 174,
        id: 172,
        name: "O‘zbekiston Respublikasi Yagona aviatsiya qidiruv-qutqaruv xizmati muvofiqlashtiruvchi kengash",
        description: "Vazirlar Mahkamasining 2015 yil 3 apreldagi “O‘zbekiston Respublikasi Yagona aviatsiya qidiruv-qutqaruv xizmati to‘g‘risidagi nizomni tasdiqlash haqida”gi 80-son qarori",
        organing: "-"
     },
     {
        "№": 175,
        id: 173,
        name: "2017-2019 yillarda Toshkent shahrining Mirzo Ulug‘bek tumanini kompleks rivojlantirish dasturi parametrlari bajarilishini taʼminlash yuzasidan vazirliklar, idoralar, tijorat banklari, mahalliy ijro etuvchi hokimiyat organlari va boshqa tashkilotlarning faoliyatini muvofiqlashtirish bo‘yicha Respublika ishchi guruhi",
        description: "Vazirlar Mahkamasining 2016 yil 12 dekabrdagi “2017–2019 yillarda Toshkent shahrining Mirzo Ulug‘bek tumanini kompleks rivojlantirish chora-tadbirlari to‘g‘risida”gi 400-son qarori",
        organing: "-"
     },
     {
        "№": 176,
        id: 174,
        name: "Respublika aholisi va byudjet tashkilotlarini ko‘mir mahsulotlari bilan taʼminlash tadbirlarini muvofiqlashtirish va amalga oshirish bo‘yicha ishchi guruh",
        description: "Vazirlar Mahkamasining 2016 yil 30 dekabrdagi “Respublika aholisi va byudjet tashkilotlariga ko‘mir mahsulotlari yetkazib berish mexanizmini takomillashtirishga doir qo‘shimcha chora-tadbirlar to‘g‘risida”gi 422-son qarori",
        organing: "-"
     },
     {
        "№": 177,
        id: 175,
        name: "2017-2021 yillarda Toshkent shahrining yo‘lovchi tashish transporti tizimini yanada takomillashtirish bo‘yicha dastur va chora-tadbirlari amalga oshirish bo‘yicha ishchi guruhi",
        description: "Vazirlar Mahkamasining 2017 yil 11 martdagi “Toshkent shahrida yo‘lovchi tashish transportini yana-da rivojlantirish chora-tadbirlari to‘g‘risida”gi  129-son qarori",
        organing: "-"
     },
     {
        "№": 178,
        id: 176,
        name: "Muddatli harbiy xizmatni va safarbarlik chaqiruvi rezervida xizmatni o‘tagan fuqarolarni ishga joylashtirish bo‘yicha Respublika komissiyasi",
        description: "Vazirlar Mahkamasining 2017 yil 2 iyundagi “Muddatli harbiy xizmatni va safarbarlik chaqiruvi rezervida xizmatni o‘tagan fuqarolarni ishga joylashtirish bo‘yicha Respublika komissiyasini hamda Qoraqalpog‘iston Respublikasi Vazirlar Kengashi, viloyatlar, Toshkent shahri, tuman va shaharlar hokimliklari huzurida tegishli komissiyalarni tashkil etish to‘g‘risida”gi 339-son qarori",
        organing: "-"
     },
     {
        "№": 179,
        id: 177,
        name: "2018-2022 yillarda respublika teatrlarining moddiy-texnika bazasini mustahkamlash va ularning faoliyatini rivojlantirishga doir chora-tadbirlar dasturi bajarilishini muvofiqlashtiruvchi komissiya",
        description: "Vazirlar Mahkamasining 2018 yil 5 yanvardagi “2018-2022 yillarda davlat teatrlarining moddiy-texnika bazasini mustahkamlash va ularning faoliyatini rivojlantirish chora-tadbirlari dasturi to‘g‘risida”gi 9-son qarori",
        organing: "-"
     },
     {
        "№": 180,
        id: 178,
        name: "O‘zbekiston Respublikasidan tashqariga yo‘lovchi tashuvchi avtomobil transportida O‘zbekiston Respublikasi fuqarolarining xavfsizligini taʼminlash bo‘yicha doimiy faoliyat ko‘rsatuvchi Respublika komissiyasi",
        description: "Vazirlar Mahkamasining 2018 yil 23 yanvardagi “Yo‘lovchi avtotransportida favqulodda vaziyatlar profilaktikasi va ularning oldini olish, shuningdek, O‘zbekiston Respublikasi Davlat chegarasidan tashqariga chiqadigan fuqarolar xavfsizligini taʼminlashga doir shoshilinch chora-tadbirlar to‘g‘risida”gi 42-son qarori",
        organing: "-"
     },
     {
        "№": 181,
        id: 179,
        name: "Dehqon xo‘jaliklari va tomorqa yer uchastkalariga qishloq xo‘jaligi ekinlarini joylashtirish, ekish va parvarishlash, shuningdek, zarur moddiy resurslar yetkazib berilishini muvofiqlashtiruvchi respublika ishchi guruhi",
        description: "Vazirlar Mahkamasining 2018 yil 18 martdagi “Dehqon xo‘jaliklari  va tomorqa yer uchastkalaridan samarali foydalanishni tashkil etish chora-tadbirlari to‘g‘risida” 205-son qarori",
        organing: "-"
     },
     {
        "№": 182,
        id: 180,
        name: "O‘zbekiston Respublikasi Vazirlar Mahkamasi huzuridagi Respublika arxitektura-shaharsozlik kengashi",
        description: "Vazirlar Mahkamasining 2018 yil 31 iyuldagi “O‘zbekiston Respublikasi Qurilish vazirligi, uning tarkibiy bo‘linmalari, shuningdek, Respublika va hududiy arxitektura-shaharsozlik kengashlari to‘g‘risidagi nizomlarni tasdiqlash haqida”gi 603-son qarori",
        organing: "-"
     },
     {
        "№": 183,
        id: 181,
        name: "YUNESKO ishlari bo‘yicha O‘zbekiston Respublikasi milliy komissiyasi",
        description: "Vazirlar Mahkamasining 2018 yil 26 noyabrdagi “YUNESKO ishlari bo‘yicha O‘zbekiston Respublikasi milliy komissiyasi faoliyatini tashkillashtirish to‘g‘risida”gi 957-son qarori",
        organing: "-"
     },
     {
        "№": 184,
        id: 182,
        name: "2018-2021 yillarda kimyoviy, biologik, radiatsiya va yadro xavfsizligini taʼminlash sohasidagi xalqaro hujjatlarni bajarish yuzasidan O‘zbekiston Respublikasining Milliy harakatlar rejasini amalga oshirish bo‘yicha ishchi guruh",
        description: "Vazirlar Mahkamasining 2018 yil 27 noyabrdagi “2018-2021 yillarda kimyoviy, biologik, radiatsiya va yadro xavfsizligini taʼminlash sohasidagi xalqaro hujjatlarni bajarish yuzasidan O‘zbekiston Respublikasining milliy harakatlar rejasini tasdiqlash to‘g‘risida”gi 968-son qarori",
        organing: "-"
     },
     {
        "№": 185,
        id: 183,
        name: "Suv omborlarining suvni muhofaza qilish zonalari doirasida yo‘l-transport va ishlab chiqarish infratuzilmasi rivojlanishini taʼminlash bo‘yicha ishchi guruh",
        description: "Vazirlar Mahkamasining 2018 yil 3 dekabrdagi “Ekoturizmni rivojlantirish va suv omborlarining suvni muhofaza qilish zonalari doirasida yer uchastkalari ajratish tartibini takomillashtirish bo‘yicha chora-tadbirlar to‘g‘risida”gi  978-son qarori",
        organing: "-"
     },
     {
        "№": 186,
        id: 184,
        name: "O‘zbekiston Respublikasi Vazirlar Mahkamasining davlat va jamiyat ehtiyojlari uchun yer uchastkalarining olib qo‘yilishi munosabati bilan jismoniy va yuridik shaxslarga yetkazilgan zararlarni qoplash bo‘yicha markazlashtirilgan jamg‘armasidan mablag‘ ajratish bo‘yicha respublika kuzatuv kengashi",
        description: "Vazirlar Mahkamasining 2018 yil 26 dekabrdagi “Davlat va jamiyat extiyojlari uchun yer uchastkalarining olib qo‘yilishi munosabati bilan jismoniy va yuridik shaxslarga yetkazilgan zararlarni qoplash bo‘yicha markazlashtirilgan jamg‘armalar mablag‘larini shakllantirish va ulardan foydalanish tartibi to‘g‘risida nizomni tasdiqlash haqida”gi 1047-son qarori",
        organing: "-"
     },
     {
        "№": 187,
        id: 185,
        name: "“Shovot” va “G‘ovuk ko‘l” kichik turizm zonalarini tashkil etish va rivojlantirish loyihalarining o‘z vaqtida va sifatli amalga oshirilishiga ko‘maklashuvchi respublika ishchi guruhi",
        description: "Vazirlar Mahkamasining 2019 yil 13 fevraldagi “2019-2020 yillarda Xorazm viloyatida turizm sohasini rivojlantirish bo‘yicha qo‘shimcha chora-tadbirlar to‘g‘risida”gi 119-son qarori",
        organing: "-"
     },
     {
        "№": 188,
        id: 186,
        name: "Qishloq xo‘jaligida iqtisodiy islohotlarni chuqurlashtirish bo‘yicha Respublika komissiyasi",
        description: "Vazirlar Mahkamasining 2019 yil 12 apreldagi “Qishloq xo‘jaligida iqtisodiy islohotlarni chuqurlashtirish bo‘yicha respublika komissiyasi faoliyatini takomillashtirish chora-tadbirlari to‘g‘risida”gi 300-son qarori",
        organing: "-"
     },
     {
        "№": 189,
        id: 187,
        name: "O‘zbekiston Respublikasi Vazirlar Mahkamasi huzuridagi Idoralararo tarif komissiyasi",
        description: "Vazirlar Mahkamasining 2019 yil 13 apreldagi “Elektr energetika tarmog‘ida tarif siyosatini takomillashtirishga doir qo‘shimcha chora-tadbirlar to‘g‘risida”gi 310-son qarori",
        organing: "-"
     },
     {
        "№": 190,
        id: 188,
        name: "Yuqori tezlikdagi temir yo‘l uchastkalarida harakat xavfsizligini taʼminlashga oid chora-tadbirlar amalga oshirilishini tashkil etish va muvofiqlashtirish, shuningdek, nazorat qilish bo‘yicha idoralararo komissiya",
        description: "Vazirlar Mahkamasining 2019 yil 8 maydagi “Yuqori tezlikdagi temir yo‘l uchastkalarida harakat xavfsizligini taʼminlashga oid chora-tadbirlar amalga oshirilishini tashkil etish va muvofiqlashtirish, shuningdek, nazorat qilish bo‘yicha idoralararo komissiya to‘g‘risidagi nizomni tasdiqlash haqida”gi  387-son qarori",
        organing: "-"
     },
     {
        "№": 191,
        id: 189,
        name: "Tarif va notarif tartibga solish kengashi",
        description: "Vazirlar Mahkamasining 2019 yil 15 maydagi “O‘zbekiston Respublikasi Investitsiyalar va tashqi savdo vazirligi huzuridagi tarif va notarif tartibga solish kengashining faoliyatini tashkil etish to‘g‘risida”gi 408-son qarori (Kengashning namunaviy tarkibi PQ-4135 qarori bilan tasdiqlangan)",
        organing: "-"
     },
     {
        "№": 192,
        id: 190,
        name: "Ijtimoiy-mehnat masalalari bo‘yicha respublika uch tomonlama komissiyasi",
        description: "Vazirlar Mahkamasining 2019 yil 3 iyuldagi “Ijtimoiy-mehnat masalalari bo‘yicha uch tomonlama komissiyalar to‘g‘risida”gi 553-son qarori",
        organing: "-"
     },
     {
        "№": 193,
        id: 191,
        name: "Andijon viloyatida yoshlarni jismoniy tarbiya va sport bilan shug‘ullanishga jalb qilish, mavjud sport inshootlarining quvvatidan samarali foydalanish, olis va chekka qishloqlarda sport inshootlarini barpo etish chora-tadbirlari rejasi amalga oshirilishini tashkil etish va nazorat qilish bo‘yicha ishchi guruhi",
        description: "Vazirlar Mahkamasining 2019 yil 30 sentyabrdagi “Andijon viloyatida yoshlarni jismoniy tarbiya va sportga jalb qilish tizimini takomillashtirish hamda mavjud sport inshootlari quvvatidan samarali foydalanish chora-tadbirlari to‘g‘risida”gi 820-son qarori",
        organing: "-"
     },
     {
        "№": 194,
        id: 192,
        name: "Fan va texnologiyalar bo‘yicha respublika kengashi",
        description: "Vazirlar Mahkamasining 2019 yil 27 noyabrdagi “Fan va texnologiyalar bo‘yicha respublika kengashi faoliyatini tashkil etish to‘g‘risida”gi 953-son qarori",
        organing: "-"
     },
     {
        "№": 195,
        id: 193,
        name: "Dasturiy mahsulotlar va axborot texnologiyalari texnologik parkining maʼmuriy kengashi",
        description: "Vazirlar Mahkamasining 2019 yil 3 dekabrdagi “Dasturiy mahsulotlar va axborot texnologiyalari texnologik parki faoliyatini yanada rivojlantirish chora-tadbirlari to‘g‘risida”gi 965-son qarori",
        organing: "-"
     },
     {
        "№": 196,
        id: 194,
        name: "Yer qaʼridan foydalanish shartlarini ishlab chiqish bo‘yicha davlat komissiyasi",
        description: "Vazirlar Mahkamasining 2019 yil 12 dekabrdagi “Yer qaʼridan foydalanish shartlarini ishlab chiqish bo‘yicha davlat komissiyasi faoliyatini yanada takomillashtirish chora-tadbirlari to‘g‘risida”gi 989-son qarori",
        organing: "-"
     },
     {
        "№": 197,
        id: 195,
        name: "Toshkent viloyatida amalga oshiriladigan yirik investitsiya loyihalari uchun qishloq xo‘jaligiga mo‘ljallangan yerlarni ajratish masalalarini ko‘rib chiqish komissiyasi",
        description: "Vazirlar Mahkamasining 2019 yil 27 dekabrdagi “Toshkent viloyatida amalga oshirilayotgan investitsiya loyihalarini qo‘llab-quvvatlash chora-tadbirlari to‘g‘risida”gi 1045-son qarori",
        organing: "-"
     },
     {
        "№": 198,
        id: 196,
        name: "O‘zbekiston Respublikasi Vazirlar Mahkamasi huzuridagi Atamalar komissiyasi",
        description: "Vazirlar Mahkamasining 2020 yil 29 yanvardagi “O‘zbekiston Respublikasi Vazirlar Mahkamasi huzuridagi Atamalar komissiyasining faoliyatini tashkil qilish chora-tadbirlari to‘g‘risida” 40-son qarori",
        organing: "-"
     },
     {
        "№": 199,
        id: 197,
        name: "Jahon savdo tashkiloti bilan ishlash bo‘yicha Idoralararo komissiya",
        description: "Vazirlar Mahkamasining 2020 yil 28 maydagi “Jahon savdo tashkiloti bilan ishlash bo‘yicha idoralararo komissiya to‘g‘risidagi nizomni tasdiqlash haqida”gi 339-son qarori (Komissiya tarkibi - Vazirlar Mahkamasining  2018 yil 24 dekabrdagi 1040-son qarori bilan tasdiqlangan)",
        organing: "-"
     },
     {
        "№": 200,
        id: 198,
        name: "“Beldirsoy-Chimyon-Nanay” Xalqaro umummavsumiy kurort (XUK)ni qurish loyihasini jadal amalga oshirish va ishga tushirish bo‘yicha Boshqaruv kengashi",
        description: "Vazirlar Mahkamasining 2020 yil 17 sentyabrdagi “Tog‘li hududlarda turizm infratuzilmasini rivojlantirishga doir qo‘shimcha chora-tadbirlar to‘g‘risida” 559-son qarori",
        organing: "-"
     },
     {
        "№": 201,
        id: 199,
        name: "O‘zbekiston Respublikasi Maktabgacha taʼlim vazirligi tomonidan tizimdagi davlat maktabgacha taʼlim tashkilotlari uchun markazlashtirilgan holda jihozlarni xarid qilish bo‘yicha komissiya",
        description: "Vazirlar Mahkamasining 2020 yil 22 dekabrdagi “Maktabgacha taʼlim va tarbiyaning davlat standartini tasdiqlash to‘g‘risida”gi 802-son qarori",
        organing: "-"
     },
     {
        "№": 202,
        id: 200,
        name: "Lotin yozuviga asoslangan o‘zbek alifbosiga bosqichma-bosqich to‘liq o‘tishni taʼminlash bo‘yicha respublika ishchi guruhi",
        description: "Vazirlar Mahkamasining 2021 yil 10 fevraldagi “Lotin yozuviga asoslangan o‘zbek alifbosiga bosqichma-bosqich to‘liq o‘tishni taʼminlash chora-tadbirlari to‘g‘risida”gi 61-son qarori",
        organing: "-"
     },
     {
        "№": 203,
        id: 201,
        name: "Subsidiyalanadigan avia va temir yo‘l qatnovi yo‘nalishlarini, avia chiptalar va mehmonxona xizmatlarini subsidiyalash miqdorini belgilash bo‘yicha maxsus komissiya",
        description: "Vazirlar Mahkamasining 2021 yil 24 fevraldagi “Ichki va ziyorat turizmini rivojlantirish bo‘yicha qo‘shimcha chora-tadbirlar to‘g‘risida”gi 100-son qarori",
        organing: "-"
     },
     {
        "№": 204,
        id: 202,
        name: "Ziyorat turizmini rivojlantirishni muvofiqlashtirish kengashi",
        description: "Vazirlar Mahkamasining 2021 yil 24 fevraldagi “Ichki va ziyorat turizmini rivojlantirish bo‘yicha qo‘shimcha chora-tadbirlar to‘g‘risida”gi 100-son qarori",
        organing: "-"
     },
     {
        "№": 205,
        id: 203,
        name: "Oziq-ovqat va qishloq xo‘jaligi mahsulotlari ishlab chiqarish, ularni ichki bozorga tizimli yetkazib berish hamda narxlarni doimiy monitoring qilish va tezkor chora-tadbirlarni amalga oshirish bo‘yicha Respublika ishchi guruhi",
        description: "Vazirlar Mahkamasining 2021 yil 15 martdagi “Ichki isteʼmol bozorini asosiy turdagi oziq-ovqat mahsulotlari bilan kafolatli taʼminlashga doir qo‘shimcha chora-tadbirlar to‘g‘risida”gi 135-son qarori",
        organing: "-"
     },
     {
        "№": 206,
        id: 204,
        name: "Dastur parametrlari bajarilishini taʼminlashda davlat va ho‘jalik boshqaruvi organlari, mahalliy davlat hokimiyati organlari, tijorat banklari faoliyatini muvofiqlashtirish va monitoring qilish bo‘yicha respublika ishchi guruhi",
        description: "Vazirlar Mahkamasining 2021 yil 3 apreldagi “2021-2023 yillarda Toshkent shahrining Yangihayot tumanini kompleks ijtimoiy-iqtisodiy rivojlantirish chora-tadbirlari to‘g‘risida”gi 177-son qarori",
        organing: "-"
     },
     {
        "№": 207,
        id: 205,
        name: "Onkogematologik va davolash qiyin bo‘lgan kasalliklarga chalingan bemorlarni sog‘lomlashtirishga ko‘maklashish jamg‘armasi mablag‘larini boshqarish kengashi",
        description: "Vazirlar Mahkamasining 2021 yil 8 apreldagi “Onkogematologik va davolash qiyin bo‘lgan kasalliklarga chalingan bemorlarni sog‘lomlashtirishga ko‘maklashish jamg‘armasi to‘g‘risidagi nizomni tasdiqlash haqida”gi 193-son qarori",
        organing: "-"
     },
     {
        "№": 208,
        id: 206,
        name: "Geologiya, kon-metallurgiya va misni qayta ishlash sanoatini rivojlantirish Loyiha ofisining boshqaruv qo‘mitasi",
        description: "Vazirlar Mahkamasining 2021 yil 4 avgustdagi “Geologiya, kon-metallurgiya va misni qayta ishlash sanoatini uzoq muddatli rivojlantirish strategiyasini amalga oshirish chora-tadbirlari to‘g‘risida”gi 498-son qarori",
        organing: "-"
     },
     {
        "№": 209,
        id: 207,
        name: "“O‘zeltexsanoat” uyushmasining Elektrotexnika sanoatini rivojlantirish jamg‘armasi Boshqaruv kengashi",
        description: "Vazirlar Mahkamasining 2021 yil 8 oktyabrdagi “O‘zbekiston Respublikasi Hukumatining ayrim qarorlariga o‘zgartirish va qo‘shimchalar kiritish to‘g‘risida”gi 631-son qarori",
        organing: "-"
     },
     {
        "№": 210,
        id: 208,
        name: "Alohida taʼlim ehtiyojlari bo‘lgan bolalarning taʼlim olishi va kasb egallashiga ko‘maklashishni tashkil etish bo‘yicha ishchi guruh",
        description: "Vazirlar Mahkamasining 2021 yil 12 oktyabrdagi “Alohida taʼlim ehtiyojlari bo‘lgan bolalarga taʼlim berishga oid normativ-huquqiy hujjatlarni tasdiqlash to‘g‘risida”gi 638-son qarori",
        organing: "-"
     },
     {
        "№": 211,
        id: 209,
        name: "Nogironligi bo‘lgan shaxslar ishlari bo‘yicha idoralararo kengash",
        description: "Vazirlar Mahkamasining 2021 yil 23 dekabrdagi “Nogironligi bo‘lgan shaxslar ishlari bo‘yicha idoralararo kengash faoliyatini yana-da takomillashtirish chora-tadbirlari to‘g‘risida”gi 769-son qarori",
        organing: "-"
     },
     {
        "№": 212,
        id: 210,
        name: "2030 yilgacha bo‘lgan davrda barqaror rivojlanish sohasidagi milliy maqsad va vazifalarni amalga oshirish bo‘yicha muvofiqlashtiruvchi kengash",
        description: "Vazirlar Mahkamasining 2022 yil 21 fevraldagi “2030 yilgacha bo‘lgan davrda barqaror rivojlanish sohasidagi milliy maqsad va vazifalarni amalga oshirishni jadallashtirish bo‘yicha qo‘shimcha chora-tadbirlar to‘g‘risida”gi  83-son qarori",
        organing: "-"
     },
     {
        "№": 213,
        id: 211,
        name: "Xotin-qizlar muammolarini tizimli ravishda hal etish, ularni ijtimoiy qo‘llab-quvvatlash bo‘yicha respublika komissiyasi",
        description: "Vazirlar Mahkamasining 2022 yil 31 martdagi “Xotin-qizlar muammolarini o‘rganish va hal etish tizimini yana-da takomillashtirish chora-tadbirlari to‘g‘risida”gi 145-son qarori",
        organing: "-"
     },
     {
        "№": 214,
        id: 212,
        name: "O‘zbekistonda madaniy boyliklar noqonuniy aylanmasining oldini olish, xorijda saqlanayotgan madaniy boyliklarni qaytarish hamda ushbu sohadagi xalqaro hamkorlikni yana-da kuchaytirish bo‘yicha respublika ishchi guruhi",
        description: "Vazirlar Mahkamasining 2022 yil 21 iyuldagi “O‘zbekistonda madaniy boyliklar noqonuniy aylanmasining oldini olish, xorijda saqlanayotgan madaniy boyliklarni qaytarish hamda ushbu sohadagi xalqaro hamkorlikni yanada kuchaytirish bo‘yicha qo‘shimcha chora-tadbirlari to‘g‘risida”gi 397-son qarori",
        organing: "-"
     },
     {
        "№": 215,
        id: 213,
        name: "“Zarafshon” milliy tabiat bog‘ini qabul qilish-topshirish ishlarini tashkil etish bo‘yicha respublika komissiyasi",
        description: "Vazirlar Mahkamasining 2022 yil 10 avgustdagi “Zarafshon” milliy tabiat bog‘ini Ekologiya va atrof muhitni muhofaza qilish davlat qo‘mitasi tizimiga o‘tkazish to‘g‘risida”gi 442-son qarori",
        organing: "-"
     },
     {
        "№": 216,
        id: 214,
        name: "Iqtisodiyot tarmoqlari, ijtimoiy soha obyektlari va ko‘p kvartirali uy-joy fondini 2022/2023 yillar kuz-qish davrida barqaror ishlashga tayyorlashni muvofiqlashtirish bo‘yicha respublika komissiyasi",
        description: "Vazirlar Mahkamasining 2022 yil 23 sentyabrdagi “Iqtisodiyot tarmoqlari, ijtimoiy soha obyektlari va ko‘p kvartirali uy-joy fondini 2022/2023 yillar kuz-qish davrida barqaror ishlashga tayyorlashni taʼminlash chora-tadbirlari to‘g‘risida”gi 522-son qarori",
        organing: "-"
     },
     {
        "№": 217,
        id: 215,
        name: "Mahallalarda xotin-qizlarning bandligini taʼminlash va salomatligini mustahkamlash markazlari faoliyati uchun davlat granti ajratish bo‘yicha respublika komissiyasi",
        description: "Vazirlar Mahkamasining 2022 yil 8 noyabrdagi “Mahallalarda xotin-qizlarning bandligini taʼminlash va salomatligini mustahkamlash markazlari faoliyatini tashkil etish chora-tadbirlari to‘g‘risida”gi 650-son qarori",
        organing: "-"
     },
     {
        "№": 218,
        id: 216,
        name: "2018-2027 yillarda muzeylar faoliyatini takomillashtirish va moddiy-texnika bazasini mustahkamlash kompleks chora-tadbirlari dasturi loyihasini ishlab chiqish bo‘yicha Respublika komissiyasi",
        description: "Vazirlar Mahkamasining 2017 yil 11 fevraldagi 119-f-son farmoyishi",
        organing: "-"
     },
     {
        "№": 219,
        id: 217,
        name: "2018-2022 yillarda respublika teatrlarining moddiy-texnika bazasini mustahkamlash va ularning ijodiy faoliyatini rivojlantirishga qaratilgan  chora-tadbirlari dasturi loyihasini ishlab chiqish bo‘yicha Respublika komissiyasi",
        description: "Vazirlar Mahkamasining 2017 yil 26 apreldagi 464-f-son farmoyishi",
        organing: "-"
     },
     {
        "№": 220,
        id: 218,
        name: "Madaniyat va aholi dam olish markazlarini o‘rganish va ularning faoliyatini takomillashtirish yuzasidan takliflar tayyorlash bo‘yicha respublika ishchi guruhi",
        description: "Vazirlar Mahkamasining 2018 yil 6 iyundagi 469-f-son farmoyishi",
        organing: "-"
     },
     {
        "№": 221,
        id: "-",
        name: "Boshqa hujjatlar bilan tashkil qilingan idoralararo kollegial organlar",
        description: "-",
        organing: "-"
     },
     {
        "№": 222,
        id: 219,
        name: "“Gender tenglik faoli” milliy ko‘rik-tanlovini tashkil etish va o‘tkazish bo‘yicha Respublika komissiyasi",
        description: "O‘zbekicton Respublikasi Oliy Majlisi Senati Kengashining 2022 yil  27 apreldagi “Yil ayoli” va “Gender tenglik faoli” milliy tanlovlarini o‘tkazish to‘g‘risida”gi QQ-340-IV-son qarori",
        organing: "-"
     },
     {
        "№": 223,
        id: 220,
        name: "“Yil ayoli” milliy tanlovini o‘tkazish bo‘yicha Respublika tashkiliy qo‘mitasi",
        description: "O‘zbekicton Respublikasi Oliy Majlisi Senati Kengashining 2022 yil  27 apreldagi “Yil ayoli” va “Gender tenglik faoli” milliy tanlovlarini o‘tkazish to‘g‘risida”gi QQ-340-IV-son qarori",
        organing: "-"
     },
     {
        "№": 224,
        id: 221,
        name: "Import qilinayotgan texnologik asbob-uskunalardan samarali foydalanishni doimiy monitoring qilish bo‘yicha respublika Ishchi guruhi ",
        description: "O‘zbekiston Respublikasi Prezidenti Sh.M.Mirziyoyev rahbarligida  2019 yil 17 sentyabr kuni kengaytirilgan tarzda o‘tkazilgan videoselektor yig‘ilishi 39-sonli bayoni",
        organing: "-"
     }
  ], []);

  return (
    <section className={cls.section}>
       <Container>
          <Title text={t('collegial_organizations')} />

          <Spacer size={50} />

          <Table
             rowKey='id'
             columns={[
                {
                   title: 'ID',
                   render: (text, record, index) => (page - 1) * SIZE + index + 1
                },
                {
                   key: 'name',
                   title: t('column_collegial_name'),
                   dataIndex: 'name'
                },
                {
                   key: 'description',
                   title: t('column_collegial_description'),
                   dataIndex: 'description'
                },
                {
                   key: 'organing',
                   title: t('column_collegial_organing'),
                   dataIndex: 'organing'
                },
             ]}
             dataSource={items.slice((page - 1) * SIZE, page * SIZE)}
             pagination={false}
             scroll={{ x: 1400 }}
          />

          <Spacer size={32} />

          <div className={cls.pagination}>
             <Pagination
                total={items.length / SIZE}
                current={page}
                pageRangeDisplayed={5}
                onChange={page => setPage(page)}
             />
          </div>
       </Container>
    </section>
  )
}

export default Collegial;