import React from 'react';

import Faq from "./sections/Faq";
import Hero from "./sections/Hero";
import Sphere from "./sections/Sphere";
import Statistic from "./sections/Statistic";
import Collegial from "./sections/Collegial";
import Organization from "./sections/Organization";

const Home = () => (
  <>
    <Hero />

    <Organization />

    <Collegial />

    <Sphere />

    <Statistic />

    <Faq />
  </>
);

export default Home;